import React from 'react';
import { useParams } from 'react-router-dom';

import PrizeNumberFour from 'pages/PrizeNumberFour';
import PrizeNumberOne from 'pages/PrizeNumberOne';
import PrizeNumberThree from 'pages/PrizeNumberThree';
import PrizeNumberTwo from 'pages/PrizeNumberTwo';

const Prize: React.FC = () => {
  const { slug } = useParams();

  if (slug === 'one') {
    return <PrizeNumberOne />;
  }
  if (slug === 'two') {
    return <PrizeNumberTwo />;
  }
  if (slug === 'three') {
    return <PrizeNumberThree />;
  }
  if (slug === 'four') {
    return <PrizeNumberFour />;
  }
  return null;
};

export default Prize;
