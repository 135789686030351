/* eslint-disable import/no-extraneous-dependencies */
import React, { forwardRef } from 'react';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import Icon from 'components/atoms/Icon';
import { formatDateDDMMYYYY } from 'utils/functions';

interface DatePickerProps {
  maxDate?: Date;
  minDate?: Date;
  defaultDate?: Date;
  handleSelectDate?: (date: Date) => void;
}
type Props = {
  value?: string;
  onClick?: () => void;
};
const ButtonRef: React.ForwardRefRenderFunction<HTMLButtonElement, Props> = (
  {
    value,
    onClick
  },
  ref,
) => (
  <>
    <Icon iconName="calendar" size="24" />
    <button type="button" onClick={onClick} ref={ref}>
      {value}
    </button>
  </>
);

const CustomInput = forwardRef(ButtonRef);
const DatePickerCustom: React.FC<DatePickerProps> = (
  {
    defaultDate, handleSelectDate, maxDate, minDate
  }
) => {
  const [value, setValue] = React.useState<Date>();
  return (
    <DatePicker
      maxDate={maxDate}
      minDate={minDate}
      wrapperClassName="m-datePicker"
      dateFormat="dd/MM/yyyy"
      selected={value || defaultDate || new Date()}
      onChange={(date: Date) => {
        setValue(date);
        if (handleSelectDate) handleSelectDate(date);
      }}
      customInput={<CustomInput value={formatDateDDMMYYYY(value)} />}
    />
  );
};

export default DatePickerCustom;
