import React from 'react';
import { Typography } from 'tfc-components';

import mapModifiers from 'utils/functions';

export interface CirclePrizeProps {
  textPrize?: string;
  textReward?: string;
  layoutFor?: LayoutFor;
  isResult?: boolean;
}

const CirclePrize: React.FC<CirclePrizeProps> = ({
  textPrize, textReward, layoutFor, isResult
}) => (
  <div className={mapModifiers('m-circlePrize', layoutFor, isResult ? 'isResult' : '')}>
    {
      textPrize && <Typography.Text extendClasses="m-circlePrize_textPrize" textStyle="uppercase" fontweight="700">{textPrize}</Typography.Text>
    }
    {
      textReward && <div className="m-circlePrize_textReward" dangerouslySetInnerHTML={{ __html: textReward }} />
    }
  </div>
);

export default CirclePrize;
