export const COLORS = {
  white: '#ffffff',
  oxfordBlue: '#011e41',
  deepKoamaru: '#344b67',
};

export const LOCALSTORAGE_KEYS = {
  ELECTROLUX_UUID: 'ELECTROLUX_UUID',
  ELECTROLUX_STEP: 'ELECTROLUX_STEP',
};

export const ROUTES_PATH = {
  HOME: '/',
  CUSTOMER_INFORMATION: '/customer-info',
  PURCHASE_INFORMATION: '/purchase-info',
  CONFIRM_OTP: '/confirm-otp',
  CONFIRM_INFORMATION: '/confirm-information',
  CONGRATULATION: '/cgr',
  PRIZES: '/prizes',
  // PRIZE_TWO: '/prizes/two',
  // PRIZE_THREE: '/prizes/three',
  // PRIZE_FOUR: '/prizes/four',
};

export const URL_CONST = {
  MEDIA_FILE_UPLOAD_CHUNK: '/api/v1/files/upload-chunk',
};

export const DRAWTIME = 400;

export const PRIZECOUNTDIGIT = 6;
