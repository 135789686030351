import React from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import {
  Button, Image, Loading, Typography
} from 'tfc-components';

import house from 'assets/images/electrolux_house.png';
// import qr from 'assets/images/qrcode.png';
import logo from 'assets/images/logoElec.png';
import remainPrize from 'assets/images/remain-prize.png';
import year from 'assets/images/years104.svg';
import useWindowDimensions from 'hooks/useWindowDemensions';
import { generateUuidService } from 'services/bills';
import { setLocalStorage } from 'services/common/storage';
import { COLORS, LOCALSTORAGE_KEYS, ROUTES_PATH } from 'utils/constants';

const Home: React.FC = () => {
  /* Hooks */
  const navigate = useNavigate();
  const { height } = useWindowDimensions();

  /* Queries */
  const { mutate: generateUuidAction, isLoading } = useMutation('generateUuidQuery', generateUuidService, {
    onSuccess(data) {
      setLocalStorage(LOCALSTORAGE_KEYS.ELECTROLUX_UUID, data);
      setLocalStorage(LOCALSTORAGE_KEYS.ELECTROLUX_STEP, '1');
      navigate(ROUTES_PATH.CUSTOMER_INFORMATION);
    },
  });

  return (
    <div className="p-home" style={{ height: `${height}px` }}>
      <div className="p-home_content">
        <div className="p-home_head">
          <div className="p-home_logo">
            <div className="p-home_leftLogo">
              <Image imgSrc={year} alt="Year Electrolux" ratio={94 / 40} />
            </div>
            <div className="p-home_rightLogo">
              <Image imgSrc={logo} alt="Logo Electrolux" ratio={134 / 34} />
            </div>
          </div>
          <div className="p-home_title">
            <Image imgSrc={house} alt="Electrolux" ratio={343 / 371} />
            {/* <div className="p-home_title_qr">
              <Image imgSrc={qr} alt="Electrolux" ratio={154 / 198} />
            </div> */}
          </div>
        </div>
        <div>
          <div className="p-home_join">
            <Button
              extendClasses="p-home_join_content"
              onClick={() => generateUuidAction()}
              loading={isLoading}
              loadingIndicator={<Loading.CircleDashed width={20} color={COLORS.white} />}
            >
              <Typography.Text extendClasses="p-home_join_text" fontweight="700">THAM GIA NGAY</Typography.Text>
            </Button>
          </div>
          <div className="p-home_bottom">
            <Image imgSrc={remainPrize} alt="Electrolux Prize" ratio={750 / 538} />
            <div className="p-home_hotline">
              <Typography.Text fontweight="700">
                Hotline:
                {' '}
                <a href="tel:1900633067">1900 633 067</a>
              </Typography.Text>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
