import { AxiosResponse } from 'axios';

import {
  CustomerInfoParams, OtpData, PurchaseInfoParams,
  SubmittedData, UploadPurchaseImagesParams, VerifyOtpParams
} from './types';

import axiosInstance from 'services/common/instance';

export const generateUuidService = async (): Promise<string> => {
  const response = await axiosInstance.post<AxiosResponse<{ uuid: string }>>('submitted-bills/generate-uuid');
  return response.data.data.uuid;
};

export const submitCustomerInfoService = async (data: CustomerInfoParams): Promise<void> => {
  await axiosInstance.post('submitted-bills/general-information', data);
};

export const uploadPurchaseImagesService = async (
  data: UploadPurchaseImagesParams
): Promise<void> => {
  await axiosInstance.post('submitted-bills/upload-images', data);
};

export const submitPurchaseInfoService = async (data: PurchaseInfoParams): Promise<void> => {
  await axiosInstance.post('submitted-bills/bill-information', data);
};

export const requestOtpService = async (uuid: string): Promise<OtpData> => {
  const response = await axiosInstance.post<AxiosResponse<OtpData>>('submitted-bills/request-otp', { uuid });
  return response.data.data;
};

export const verifyOtpService = async (data: VerifyOtpParams): Promise<void> => {
  await axiosInstance.post<AxiosResponse<OtpData>>('submitted-bills/verify-otp', data);
};

export const getSubmitedDataService = async (uuid: string): Promise<SubmittedData> => {
  const response = await axiosInstance.get<AxiosResponse<SubmittedData>>(`submitted-bills/steps?uuid=${uuid}`);
  return response.data.data;
};

export const completeBillService = async (uuid: string): Promise<void> => {
  await axiosInstance.post('submitted-bills/complete-bill', { uuid });
};
