import React from 'react';
import { Container } from 'react-bootstrap';
import { Loading } from 'tfc-components';

import useWindowDimensions from 'hooks/useWindowDemensions';
import { COLORS } from 'utils/constants';
import mapModifiers from 'utils/functions';

interface PageLayoutProps {
  loading?: boolean;
  light?: boolean;
  children?: React.ReactNode;
  footer?: React.ReactNode;
}

const PageLayout: React.FC<PageLayoutProps> = ({
  loading, light, footer, children
}) => {
  const { height } = useWindowDimensions();

  return (
    <div className={mapModifiers('o-pageLayout', light && 'light')} style={{ height: `${height}px` }}>
      <div className="o-pageLayout_content">
        {children}
      </div>
      {footer && (
        <footer className="o-pageLayout_footer">
          <div className="o-pageLayout_footer_content">
            <Container>
              {footer}
            </Container>
          </div>
        </footer>
      )}
      {loading && (
        <div className="o-pageLayout_loading">
          <Loading.CircleDashed extendClasses="o-pageLayout_loading_indicator" color={COLORS.oxfordBlue} width={48} />
        </div>
      )}
    </div>
  );
};

export default PageLayout;
