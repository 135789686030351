import { AxiosResponse } from 'axios';

import { ProductData } from './types';

import axiosInstance from 'services/common/instance';

const getAllProductsService = async (): Promise<ProductData[]> => {
  const response = await axiosInstance.get<AxiosResponse<ProductData[]>>('products');
  return response.data.data;
};

export default getAllProductsService;
