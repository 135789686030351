import React from 'react';

import mapModifiers from 'utils/functions';

export const iconList = {
  arrowDown: 'arrowDown',
  camera: 'camera',
  plus: 'plus',
  calendar: 'calendar',
  arrowBack: 'arrowBack',
  clear: 'clear',
  uploadPhoto: 'uploadPhoto',
  clearImage: 'clearImage'
};

export type IconName = keyof typeof iconList;

export type IconSize = '18' | '24' | '80';
interface IconProps {
  iconName: IconName;
  size?: IconSize;
}

const Icon: React.FC<IconProps> = ({ iconName, size }) => (
  <i className={mapModifiers('a-icon', iconName, size)} />
);

Icon.defaultProps = {
  size: undefined,
};

export default Icon;
