import React, {
  Fragment, useEffect, useRef, useState,
} from 'react';
import { Input } from 'tfc-components';

import mapModifiers from 'utils/functions';

interface OtpInputProps {
  isError?: boolean;
  handleSubmit?: (arg: string) => void;
  handleChangeOtp?: (otp: string) => void;
}

const OtpInput: React.FC<OtpInputProps> = ({ isError, handleSubmit, handleChangeOtp }) => {
  const numInput = 6;
  const [otp, setOtp] = useState(Array(numInput).fill(''));

  const otpRef = [
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
  ];
  useEffect(() => {
    if (otp[0].length > 1) {
      setOtp(otp[0].split(''));
    }
    if (handleChangeOtp && otp.join('').length > 0 && otp.join('').length < numInput) {
      handleChangeOtp(otp.join(''));
    }
    if (handleSubmit) {
      if (otp.join('').length === numInput) {
        handleSubmit(otp.join(''));
      } else {
        handleSubmit('');
      }
    }
  }, [handleChangeOtp, handleSubmit, otp]);

  useEffect(() => {
    otpRef[0].current?.focus();
    setOtp(Array(numInput).fill(''));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  return (
    <div className="o-otpInput">
      <div className="o-otpInput_wrapper">
        {otp.map((_, idx) => (
          <Fragment key={`item${idx.toString()}`}>
            <Input
              id={`otpInput${idx + 1}`}
              name={`otpInput${idx + 1}`}
              type="number"
              maxLength={1}
              ref={otpRef[idx]}
              extendClasses={mapModifiers('custom-input', isError ? 'error' : '')}
              value={otp[idx]}
              onFocus={({ target }) => target.select()}
              autoComplete="off"
              inputMode="numeric"
              onChange={({ target: { value } }) => {
                const intValue = parseInt(value, 10);
                const formatedValue = intValue ? String(intValue % 10) : value;

                setOtp((oldOtp) => oldOtp.map((v, nth) => (idx === nth ? formatedValue : v)));

                if (value.length > 0) {
                  if (idx < numInput - 1) {
                    otpRef[idx + 1].current?.focus();
                  }
                }
              }}
              onKeyUp={({ key }) => {
                const { current } = otpRef[idx];
                if (!current) {
                  return;
                }
                if (key === 'Backspace' && idx > 0) {
                  setOtp((oldOtp) => oldOtp.map((v, nth) => (idx === nth ? '' : v)));
                  current.value = '';
                  otpRef[idx - 1].current?.focus();
                }
              }}
            />
          </Fragment>
        ))}
      </div>
    </div>
  );
};

OtpInput.defaultProps = {
  handleSubmit: undefined,
};

export default OtpInput;
