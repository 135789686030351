import React from 'react';
import { Typography } from 'tfc-components';

import CirclePrize, { CirclePrizeProps } from 'components/molecules/CirclePrize';
import FrameResult from 'components/organisms/FrameResult';
import mapModifiers from 'utils/functions';

interface LayoutResultProps extends CirclePrizeProps {
  children?: React.ReactNode;
  heading?: string;
  imagePrize?: string;
  resultPrizes: Array<Array<Prize>>;
  layoutFor?: LayoutFor;
}

const LayoutResult: React.FC<LayoutResultProps> = ({
  children,
  heading,
  resultPrizes,
  imagePrize,
  textPrize,
  textReward,
  layoutFor
}) => (
  <div className={mapModifiers('t-layoutResult', layoutFor)}>
    {
      heading && (
        <Typography.Text extendClasses="t-layoutResult_heading"><span dangerouslySetInnerHTML={{ __html: heading || '' }} /></Typography.Text>
      )
    }
    <div className="t-layoutResult_content">
      <div className="t-layoutResult_left">
        {
          (layoutFor === 'prize-1' || layoutFor === 'prize-2') ? (
            <CirclePrize textPrize={textPrize} textReward={textReward} isResult={layoutFor !== 'prize-1'} />
          ) : (
            <div className="t-layoutResult_left_wrapPrize">
              <Typography.Text extendClasses="t-layoutResult_left_wrapPrize_textPrizeThree" fontweight="700">
                {textPrize}
              </Typography.Text>
              <div
                className="t-layoutResult_left_wrapPrize_textNamePrize"
                dangerouslySetInnerHTML={{ __html: textReward || '' }}
              />
            </div>
          )
        }
        <img alt={textPrize} src={imagePrize} className="t-layoutResult_image" />
      </div>
      <div className="t-layoutResult_right">
        <FrameResult
          resultPrizes={resultPrizes}
          layoutFor={layoutFor}
        />
      </div>
    </div>
    {children}
  </div>
);

LayoutResult.defaultProps = {
  children: undefined,
};

export default LayoutResult;
