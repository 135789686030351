/* eslint-disable jsx-a11y/media-has-caption */
import React, {
  useCallback, useEffect, useMemo, useRef, useState
} from 'react';
import { useMutation } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { Loading, Typography } from 'tfc-components';

import endAudio from 'assets/audio/wheel-end.mp3';
import loopAudio from 'assets/audio/wheel-loop.mp3';
import startAudio from 'assets/audio/wheel-start.mp3';
import voucher from 'assets/images/voucher.png';
import voucher_4_result from 'assets/images/voucher_4_result.png';
import ButtonLuckyDraw from 'components/molecules/ButtonLuckyDraw';
import FramePrize from 'components/organisms/FramePrize';
import CustomModal from 'components/organisms/Modal';
import Notify from 'components/organisms/Notify';
import { PrizeRef } from 'components/organisms/Prize';
import DrawLayout from 'components/templates/DrawLayout';
import LayoutResult from 'components/templates/LayoutResult';
import useDebounce from 'hooks/useDebounce';
import useDidMount from 'hooks/useDidMount';
import { getLuckyDrawMatch4thService, getLuckyDrawMatchSummaryService } from 'services/luckyDrawMatch';
import { useAppSelector } from 'store/hooks';
import mapModifiers, { formatPhoneNumber } from 'utils/functions';

const PrizeNumberFour: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const phaseParams = searchParams.get('phase') ? Number(searchParams.get('phase')) : 0;
  const luckyDrawFourthDuration = useAppSelector((
    state
  ) => state.systems.system?.others.luckyDrawFourthDuration);
  /* States */
  const [isResult, setIsResult] = React.useState<boolean>(false);
  const [prizes, setPrizes] = React.useState<Array<Prize>>([]);
  const [keyup, setKeyup] = useState<KeyboardEvent | undefined>(undefined);
  const [playing, setPlaying] = useState(false);
  const [isShowErrorNotify, setIsShowErrorNotify] = useState({
    isOpen: false,
    message: '',
  });
  const [phase, setPhase] = useState<number>(phaseParams);
  const [ended, setEnded] = useState(false);
  const [drawRow, setDrawRow] = useState(20);

  /* Refs */
  const draw1Ref = useRef<Array<PrizeRef>>(Array(7).fill(null));
  const draw2Ref = useRef<Array<PrizeRef>>(Array(7).fill(null));
  const draw3Ref = useRef<Array<PrizeRef>>(Array(6).fill(null));
  const start = useRef<HTMLAudioElement>(null);
  const loop = useRef<HTMLAudioElement>(null);
  const end = useRef<HTMLAudioElement>(null);

  /* Variables */
  const buffer = 0.4;
  const timerDelayDraw = useMemo(() => (luckyDrawFourthDuration
    ? luckyDrawFourthDuration * 1000 : 1000), [luckyDrawFourthDuration]);

  /* Functions */

  const { mutate: luckyDrawSummaryMutate, data, isLoading } = useMutation(
    'prizeSummary',
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-shadow
    (phase: number) => getLuckyDrawMatchSummaryService(),
    {
      onSuccess: (res, variables) => {
        const { matches, random, quantity } = res.summary.fourth;
        const maxPhase = Math.ceil(quantity / random);
        if (variables > maxPhase) {
          setSearchParams({ phase: `${maxPhase}` });
          setPhase(maxPhase);
        }
        if (Math.ceil(matches.length / random) + 1 < variables) {
          setSearchParams({ phase: `${Math.ceil(matches.length / random) + 1}` });
          setPhase(Math.ceil(matches.length / random) + 1);
        }
        const correctPhase = variables > maxPhase ? maxPhase : variables;
        const phaseLuckyDrawData = matches.slice(
          random * (correctPhase - 1),
          random * correctPhase
        ).filter((item) => !!item);
        const regexPhoneFromMatch = phaseLuckyDrawData.map((item) => ({
          luckyCode: item.luckyCode,
          phone: formatPhoneNumber(item.phone),
        }));

        if (phaseLuckyDrawData.length === random) {
          setIsResult(true);
        }
        setPrizes(regexPhoneFromMatch);
        phaseLuckyDrawData.forEach((item, index) => {
          if (index <= 6) {
            draw1Ref.current[index]?.handleResult(item.luckyCode, true);
          }
          if (index > 6 && index < 14) {
            draw2Ref.current[index - 7]?.handleResult(item.luckyCode, true);
          }
          if (index > 13) {
            draw3Ref.current[index - 14]?.handleResult(item.luckyCode, true);
          }
        });
      },
      onError: (error: any) => {
        if (error.length > 0) {
          switch (error[0].code) {
            case 'outOfRange':
              setIsShowErrorNotify({
                isOpen: true,
                message: 'Chưa đến thời gian để quay',
              });
              break;
            case 'limitedLuckyCode':
              setIsShowErrorNotify({
                isOpen: true,
                message: 'Đã hết mã quay số',
              });
              break;
            case 'luckyDrawLocked':
              setIsShowErrorNotify({
                isOpen: true,
                message: 'Hệ thống đang quay số. Vui lòng thử lại sau',
              });
              break;
            default:
              break;
          }
        } else {
          setIsShowErrorNotify({
            isOpen: true,
            message: 'Hệ thống đang có vấn đề',
          });
        }
      }
    }
  );

  const result = useCallback((codes: string[]) => {
    codes.forEach((item, index) => {
      setTimeout(() => {
        if (index === codes.length - 1) {
          setPlaying(false);
        }
        if (index <= 6) {
          draw1Ref.current[index]?.handleResult(item);
          if (index + 1 === 7) {
            draw2Ref.current[index - 6]?.handleDraw();
          } else {
            draw1Ref.current[index + 1]?.handleDraw();
          }
        }
        if (index > 6 && index < 14) {
          draw2Ref.current[index - 7]?.handleResult(item);
          if (index + 1 === 14) {
            draw3Ref.current[index - 13]?.handleDraw();
          } else {
            draw2Ref.current[index - 6]?.handleDraw();
          }
        }
        if (index > 13) {
          draw3Ref.current[index - 14]?.handleResult(item);
          draw3Ref.current[index - 13]?.handleDraw();
        }
      }, index * timerDelayDraw);
    });
  }, [timerDelayDraw]);

  const { mutate: drawMutate, data: currentDataDraw } = useMutation(
    'prizeNumberFour',
    (params: { phase: number, timeDelay: number }) => getLuckyDrawMatch4thService(params.phase),
    {
      onSuccess: (res, variables) => {
        setTimeout(() => {
          result(res.luckyCodes);
        }, variables.timeDelay);
        setPrizes([...prizes, ...res.luckyCodes.map((item) => ({ luckyCode: item, phone: '' }))]);
      },
      onError: (error: any) => {
        setPlaying(false);
        draw1Ref.current.forEach((element) => {
          element.handleReset();
        });
        draw2Ref.current.forEach((element) => {
          element.handleReset();
        });
        if (error.length > 0) {
          switch (error[0].code) {
            case 'outOfRange':
              setIsShowErrorNotify({
                isOpen: true,
                message: 'Chưa đến thời gian để quay',
              });
              break;
            case 'limitedLuckyCode':
              setIsShowErrorNotify({
                isOpen: true,
                message: 'Đã hết mã quay số',
              });
              break;
            case 'luckyDrawLocked':
              setIsShowErrorNotify({
                isOpen: true,
                message: 'Hệ thống đang quay số. Vui lòng thử lại sau',
              });
              break;
            case 'phaseExisted':
              setIsShowErrorNotify({
                isOpen: true,
                message: 'Đợt quay đã tồn tại',
              });
              break;
            default:
              break;
          }
        } else {
          setIsShowErrorNotify({
            isOpen: true,
            message: 'Hệ thống đang có vấn đề',
          });
        }
      }
    }
  );

  const action = useCallback(async () => {
    setPlaying(true);
    await start.current?.play();
    loop.current?.play();
    draw1Ref.current[0]?.handleDraw();
    drawMutate({ phase, timeDelay: timerDelayDraw });
  }, [drawMutate, phase, timerDelayDraw]);
  /* End Draw */

  const keyUpListener = useCallback((event: KeyboardEvent) => {
    setKeyup(event);
  }, []);

  const drawListener = useCallback((key: string) => {
    if (isResult && key === 'p') {
      if (phase < (data?.summary.fourth.quantity || 0) / (data?.summary.fourth.random || 1)) {
        setIsResult(false);
        setPrizes([]);
        setSearchParams({ phase: `${phase + 1}` });
        setPhase((prev) => prev + 1);
        luckyDrawSummaryMutate(phase + 1);
      } else {
        setEnded(true);
      }
    }
    if (!isResult && key === 'p' && prizes.length > 0 && !playing) {
      setIsResult(true);
      luckyDrawSummaryMutate(phase);
    }
    if (key === ' ' && !isResult && prizes.length === 0 && ((currentDataDraw && currentDataDraw.summary.allowContinue) || !currentDataDraw)) {
      action();
    }
  }, [action, data, luckyDrawSummaryMutate,
    isResult, phase, setSearchParams, prizes, currentDataDraw, playing]);

  /* Effects */
  useDidMount(() => {
    luckyDrawSummaryMutate(phase);
  });

  useEffect(() => {
    const loopListener = () => {
      if (!loop.current || !end.current) {
        return;
      }

      if (loop.current.currentTime > loop.current.duration - buffer) {
        if (!playing) {
          end.current.play();
        } else {
          loop.current.currentTime = 0;
          loop.current.play();
        }
      }
    };
    loop.current?.addEventListener('timeupdate', loopListener);
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      loop.current?.removeEventListener('timeupdate', loopListener);
    };
  }, [end, loop, playing]);

  useDebounce(() => {
    if (keyup) {
      drawListener(keyup.key);
    }
  }, 500, [keyup]);

  useEffect(() => {
    document.addEventListener('keydown', keyUpListener);
    return () => {
      document.removeEventListener('keydown', keyUpListener);
    };
  }, [keyUpListener]);

  useEffect(() => {
    if (data) {
      if (phase * 20 > data.summary.fourth.quantity) {
        setDrawRow(data.summary.fourth.quantity - (phase - 1) * 20);
      } else {
        setDrawRow(20);
      }
    }
  }, [data, phase]);

  return (
    <div className="p-prizeFour">
      <DrawLayout isResult={isResult}>
        {
          isLoading && (
            <div className="loading">
              <Loading.Circle width={50} color="#21396f" />
            </div>
          )
        }
        {
          isResult ? (
            <LayoutResult
              resultPrizes={drawRow > 10 ? [prizes.slice(0, 10),
              prizes.slice(10, 20)] : [prizes.slice(0, 10)]}
              layoutFor="prize-4"
              imagePrize={voucher_4_result}
              textPrize="GIẢI TƯ"
              textReward="Thẻ<br />điện thoại<br />300.000VND"
              heading={`GIẢI TƯ - ${data?.summary.fourth.quantity || 1001} GIẢI <br />QUAY LẦN ${phase < 10 ? `0${phase}` : phase}: ${drawRow < 10 ? `0${drawRow}` : drawRow} GIẢI`}
            />
          ) : (
            <div className={mapModifiers('p-prizeFour_container', drawRow < 8 && 'one')}>
              <div className="p-prizeFour_phase">
                <Typography.Text extendClasses="p-prizeFour_title" fontweight="600" textStyle="center">
                  Giải Tư -
                  {' '}
                  {data?.summary.fourth.quantity || 1001}
                  {' '}
                  Giải
                  <br />
                  Quay lần
                  {' '}
                  {phase < 10 ? `0${phase}` : phase}
                  :
                  {' '}
                  {drawRow < 10 ? `0${drawRow}` : drawRow}
                  {' '}
                  giải
                </Typography.Text>
              </div>
              <div className="p-prizeFour_left">
                <img alt="Reward" src={voucher} className="p-prizeFour_reward" />
                <div className="p-prizeFour_wrapPrize">
                  <Typography.Text extendClasses="p-prizeFour_textPrizeFour" fontweight="700">
                    Giải Tư
                  </Typography.Text>
                  <div
                    className="p-prizeFour_textNamePrize"
                    dangerouslySetInnerHTML={{ __html: 'Thẻ điện thoại <br /> 300.000VND' }}
                  />
                </div>
              </div>
              <div className="p-prizeFour_draw">
                <div className="p-prizeFour_right">
                  <div className="p-prizeFour_right-item">
                    <FramePrize duration={0} layoutFor="prize-4" quantity={drawRow > 7 ? 7 : drawRow} drawValueRef={draw1Ref.current} />
                  </div>
                  {drawRow > 7 && (
                    <div className="p-prizeFour_right-item">
                      <FramePrize duration={0} layoutFor="prize-4" quantity={7} drawValueRef={draw2Ref.current} />
                    </div>
                  )}
                  {drawRow > 14 && (
                    <div className="p-prizeFour_right-item">
                      <FramePrize duration={0} layoutFor="prize-4" quantity={6} drawValueRef={draw3Ref.current} />
                    </div>
                  )}
                </div>
                <div className="p-prizeFour_btnDraw">
                  <ButtonLuckyDraw
                    disabled={!data?.summary.fourth.allowContinue
                      || playing || prizes.length === data.summary.fourth.random
                      || (currentDataDraw && !currentDataDraw.summary.allowContinue)}
                    onHandleDraw={() => { }}
                    textButton="Quay số"
                  />
                </div>
              </div>
              <audio id="startAudio" ref={start} src={startAudio} />
              <audio id="loopAudio" ref={loop} src={loopAudio} />
              <audio id="endAudio" ref={end} src={endAudio} />
            </div>
          )
        }
        <Notify
          isOpen={isShowErrorNotify.isOpen}
          errorMessage={isShowErrorNotify.message}
          onHandleClose={() => setIsShowErrorNotify({ isOpen: false, message: '' })}
        />
        <CustomModal
          variant="maxWidth600"
          isOpen={ended}
          handleClose={() => setEnded(false)}
        >
          <div className="o-notify_popup">
            <Typography.Text textStyle="center" extendClasses="o-notify_popup_text" fontweight="700">
              Đã hết lượt quay cho Giải Tư. Vui lòng chọn Giải khác.
            </Typography.Text>
          </div>
        </CustomModal>
      </DrawLayout>
    </div>
  );
};

export default PrizeNumberFour;
