import { AxiosResponse } from 'axios';

import { ShopData } from './types';

import axiosInstance from 'services/common/instance';

const getAllShopsService = async (): Promise<ShopData[]> => {
  const response = await axiosInstance.get<AxiosResponse<ShopData[]>>('shops');
  return response.data.data;
};

export default getAllShopsService;
