/* eslint-disable jsx-a11y/media-has-caption */
import React, {
  useCallback, useEffect, useMemo, useRef, useState
} from 'react';
import { useMutation } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { Loading, Typography } from 'tfc-components';

import endAudio from 'assets/audio/wheel-end.mp3';
import loopAudio from 'assets/audio/wheel-loop.mp3';
import startAudio from 'assets/audio/wheel-start.mp3';
import phone from 'assets/images/phone.png';
import ButtonLuckyDraw from 'components/molecules/ButtonLuckyDraw';
import CirclePrize from 'components/molecules/CirclePrize';
import FramePrize from 'components/organisms/FramePrize';
import CustomModal from 'components/organisms/Modal';
import Notify from 'components/organisms/Notify';
import { PrizeRef } from 'components/organisms/Prize';
import DrawLayout from 'components/templates/DrawLayout';
import LayoutResult from 'components/templates/LayoutResult';
import useDebounce from 'hooks/useDebounce';
import useDidMount from 'hooks/useDidMount';
import { getLuckyDrawMatch2ndService, getLuckyDrawMatchSummaryService } from 'services/luckyDrawMatch';
import { useAppSelector } from 'store/hooks';
import { formatPhoneNumber } from 'utils/functions';

const PrizeNumberTwo: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const phaseParams = searchParams.get('phase') ? Number(searchParams.get('phase')) : 1;
  const luckyDrawSecondDuration = useAppSelector((
    state
  ) => state.systems.system?.others.luckyDrawSecondDuration);
  /* States */
  const [isResult, setIsResult] = React.useState<boolean>(false);
  const [prizes, setPrizes] = React.useState<Array<Prize>>([]);
  const [keyup, setKeyup] = useState<KeyboardEvent | undefined>(undefined);
  const [playing, setPlaying] = useState(false);
  const [isShowErrorNotify, setIsShowErrorNotify] = useState({
    isOpen: false,
    message: '',
  });
  const [phase, setPhase] = useState<number>(phaseParams);
  const [ended, setEnded] = useState(false);
  const [drawIdx, setDrawIdx] = React.useState<number>(0);
  const [isDraw, setIsDraw] = useState(false);
  const [drawRow, setDrawRow] = useState(5);

  /* Refs */
  const drawRef = useRef<Array<PrizeRef>>(Array(5).fill(null));
  const start = useRef<HTMLAudioElement>(null);
  const loop = useRef<HTMLAudioElement>(null);
  const end = useRef<HTMLAudioElement>(null);

  /* Variables */
  const buffer = 0.4;
  const timerDelayDraw = useMemo(() => (luckyDrawSecondDuration
    ? Math.ceil(luckyDrawSecondDuration * 1000 / 6) : 1667), [luckyDrawSecondDuration]);

  /* Functions */

  const { mutate: luckyDrawSummaryMutate, data, isLoading } = useMutation(
    ['prizeSummary', phase],
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-shadow
    (phase: number) => getLuckyDrawMatchSummaryService(),
    {
      onSuccess: (res, variables) => {
        const {
          matches, quantity
        } = res.summary.second;
        const maxPhase = Math.ceil(quantity / 5);

        if (variables > maxPhase) {
          setSearchParams({ phase: `${maxPhase}` });
          setPhase(maxPhase);
        }
        if (Math.ceil(matches.length / 5) + 1 < variables) {
          setSearchParams({ phase: `${Math.ceil(matches.length / 5) + 1}` });
          setPhase(Math.ceil(matches.length / 5) + 1);
        }
        const correctPhase = variables > maxPhase ? maxPhase : variables;
        const phaseLuckyDrawData = matches.slice(
          5 * (correctPhase - 1),
          5 * correctPhase
        ).filter((item) => !!item);
        const regexPhoneFromMatch = phaseLuckyDrawData.map((item) => ({
          luckyCode: item ? item.luckyCode : '',
          phone: item ? formatPhoneNumber(item.phone) : '',
        }));

        if (phaseLuckyDrawData.length === 5) {
          setIsResult(true);
        }
        setPrizes(regexPhoneFromMatch);
        setDrawIdx(phaseLuckyDrawData.length);
        phaseLuckyDrawData.forEach((item, index) => {
          drawRef.current[index].handleResult(item.luckyCode, true);
        });
      },
      onError: (error: any) => {
        if (error.length > 0) {
          switch (error[0].code) {
            case 'outOfRange':
              setIsShowErrorNotify({
                isOpen: true,
                message: 'Chưa đến thời gian để quay',
              });
              break;
            case 'limitedLuckyCode':
              setIsShowErrorNotify({
                isOpen: true,
                message: 'Đã hết mã quay số',
              });
              break;
            case 'luckyDrawLocked':
              setIsShowErrorNotify({
                isOpen: true,
                message: 'Hệ thống đang quay số. Vui lòng thử lại sau',
              });
              break;
            default:
              break;
          }
        } else {
          setIsShowErrorNotify({
            isOpen: true,
            message: 'Hệ thống đang có vấn đề',
          });
        }
      }
    }
  );

  const result = useCallback((code: string) => {
    setPlaying(false);
    drawRef.current[drawIdx]?.handleResult(code);
    setDrawIdx((prev) => prev + 1);
    setTimeout(() => {
      setIsDraw(false);
    }, timerDelayDraw * 5);
  }, [drawIdx, timerDelayDraw]);

  const { mutate: drawMutate, data: currentDataDraw } = useMutation(
    'prizeNumberTwo',
    (params: { phase: number, timeDelay: number }) => getLuckyDrawMatch2ndService(params.phase),
    {
      onSuccess: (res, variables) => {
        setPrizes([...prizes, { luckyCode: res.luckyCodes[0] || '', phone: '' }]);
        setTimeout(() => {
          result(res.luckyCodes[0]);
        }, variables.timeDelay);
      },
      onError: (error: any) => {
        setPlaying(false);
        drawRef.current.forEach((element) => {
          element.handleReset();
        });
        if (error.length > 0) {
          switch (error[0].code) {
            case 'outOfRange':
              setIsShowErrorNotify({
                isOpen: true,
                message: 'Chưa đến thời gian để quay',
              });
              break;
            case 'limitedLuckyCode':
              setIsShowErrorNotify({
                isOpen: true,
                message: 'Đã hết mã quay số',
              });
              break;
            case 'luckyDrawLocked':
              setIsShowErrorNotify({
                isOpen: true,
                message: 'Hệ thống đang quay số. Vui lòng thử lại sau',
              });
              break;
            case 'phaseExisted':
              setIsShowErrorNotify({
                isOpen: true,
                message: 'Đợt quay đã tồn tại',
              });
              break;
            default:
              break;
          }
        } else {
          setIsShowErrorNotify({
            isOpen: true,
            message: 'Hệ thống đang có vấn đề',
          });
        }
      }
    }
  );

  const action = useCallback(async () => {
    setPlaying(true);
    setIsDraw(true);
    await start.current?.play();
    loop.current?.play();
    drawRef.current[drawIdx].handleDraw();
    drawMutate({ phase: (phase - 1) * 5 + drawIdx + 1, timeDelay: timerDelayDraw });
  }, [drawIdx, drawMutate, phase, timerDelayDraw]);
  /* End Draw */

  const keyUpListener = useCallback((event: KeyboardEvent) => {
    setKeyup(event);
  }, []);

  const drawListener = useCallback((key: string) => {
    if (isResult && key === 'p') {
      if (phase < (data?.summary.second.quantity || 0) / 5) {
        setIsResult(false);
        setSearchParams({ phase: `${phase + 1}` });
        setPhase((prev) => prev + 1);
        luckyDrawSummaryMutate(phase + 1);
        setPrizes([]);
      } else {
        setEnded(true);
      }
    }
    if (!isResult && key === 'p' && prizes.length === drawRow && drawIdx === drawRow && !isDraw) {
      setIsResult(true);
      luckyDrawSummaryMutate(phase);
    }
    if (key === ' ' && !isResult && drawIdx < 5 && ((currentDataDraw && currentDataDraw.summary.allowContinue) || !currentDataDraw) && !isDraw) {
      action();
    }
  }, [isResult, setSearchParams, phase, luckyDrawSummaryMutate,
    action, data, prizes, drawIdx, currentDataDraw, isDraw, drawRow]);

  /* Effects */
  useDidMount(() => {
    luckyDrawSummaryMutate(phase);
  });

  useEffect(() => {
    const loopListener = () => {
      if (!loop.current || !end.current) {
        return;
      }

      if (loop.current.currentTime > loop.current.duration - buffer) {
        if (!playing) {
          end.current.play();
        } else {
          loop.current.currentTime = 0;
          loop.current.play();
        }
      }
    };
    loop.current?.addEventListener('timeupdate', loopListener);
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      loop.current?.removeEventListener('timeupdate', loopListener);
    };
  }, [end, loop, playing]);

  useDebounce(() => {
    if (keyup) {
      drawListener(keyup.key);
    }
  }, 500, [keyup]);

  useEffect(() => {
    document.addEventListener('keydown', keyUpListener);
    return () => {
      document.removeEventListener('keydown', keyUpListener);
    };
  }, [keyUpListener]);

  useEffect(() => {
    if (data) {
      if (phase * 5 > data.summary.second.quantity) {
        setDrawRow(data.summary.second.quantity - (phase - 1) * 5);
      } else {
        setDrawRow(5);
      }
    }
  }, [data, phase]);

  return (
    <div className="p-prizeTwo">
      <DrawLayout isResult={isResult}>
        {
          isLoading && (
            <div className="loading">
              <Loading.Circle width={50} color="#21396f" />
            </div>
          )
        }
        {
          isResult ? (
            <LayoutResult
              resultPrizes={[prizes]}
              layoutFor="prize-2"
              imagePrize={phone}
              textPrize="GIẢI NHÌ"
              textReward="IPHONE <br/> 15 PRO"
              heading={`GIẢI NHÌ - ${data?.summary.second.quantity || 11} GIẢI <br />QUAY LẦN ${phase < 10 ? `0${phase}` : phase}: ${drawRow < 10 ? `0${drawRow}` : drawRow} GIẢI`}
            />
          ) : (
            <div className="p-prizeTwo_container">
              <div className="p-prizeTwo_phase">
                <Typography.Text extendClasses="p-prizeTwo_title" fontweight="600" textStyle="center">
                  Giải Nhì -
                  {' '}
                  {data?.summary.second.quantity}
                  {' '}
                  Giải
                  <br />
                  Quay lần
                  {' '}
                  {phase < 10 ? `0${phase}` : phase}
                  :
                  {' '}
                  {drawRow < 10 ? `0${drawRow}` : drawRow}
                  {' '}
                  giải
                </Typography.Text>
              </div>
              <img alt="Reward" src={phone} className="p-prizeTwo_reward" />
              <div className="p-prizeTwo_wrapCircle">
                <CirclePrize textPrize="GIẢI NHÌ" textReward="IPHONE <br/> 15 PRO" />
              </div>
              <FramePrize
                duration={timerDelayDraw}
                quantity={drawRow}
                drawValueRef={drawRef.current}
              >
                <ButtonLuckyDraw
                  disabled={!data?.summary.second.allowContinue
                    || isDraw || prizes.length === drawRow
                    || (currentDataDraw && !currentDataDraw.summary.allowContinue)}
                  onHandleDraw={() => { }}
                  textButton="Quay số"
                />
              </FramePrize>
              <audio id="startAudio" ref={start} src={startAudio} />
              <audio id="loopAudio" ref={loop} src={loopAudio} />
              <audio id="endAudio" ref={end} src={endAudio} />
            </div>
          )
        }
        <Notify
          isOpen={isShowErrorNotify.isOpen}
          errorMessage={isShowErrorNotify.message}
          onHandleClose={() => setIsShowErrorNotify({ isOpen: false, message: '' })}
        />
        <CustomModal
          variant="maxWidth600"
          isOpen={ended}
          handleClose={() => setEnded(false)}
        >
          <div className="o-notify_popup">
            <Typography.Text textStyle="center" extendClasses="o-notify_popup_text" fontweight="700">
              Đã hết lượt quay cho Giải Nhì. Vui lòng chọn Giải khác.
            </Typography.Text>
          </div>
        </CustomModal>
      </DrawLayout>
    </div>
  );
};

export default PrizeNumberTwo;
