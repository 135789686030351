import React from 'react';
import { Typography } from 'tfc-components';

import mapModifiers from 'utils/functions';

interface PrizeLabelProps {
  prizes: Array<Prize>;
  layoutFor?: LayoutFor;
}

const PrizeLabel: React.FC<PrizeLabelProps> = ({ prizes, layoutFor }) => (
  <div className={mapModifiers('m-prizeLabel', layoutFor)}>
    <div className="m-prizeLabel_col">
      <div><Typography.Text extendClasses="m-prizeLabel_label">Mã trúng thưởng</Typography.Text></div>
      {
        prizes.map((prize) => (
          <div key={`code-prize-${prize.luckyCode}`} className="m-prizeLabel_value">
            <Typography.Text extendClasses="m-prizeLabel_value-text">{prize.luckyCode}</Typography.Text>
          </div>
        ))
      }
    </div>
    <div className="m-prizeLabel_col">
      <div><Typography.Text extendClasses="m-prizeLabel_label">Số điện thoại</Typography.Text></div>
      {
        prizes.map((prize, index) => (
          <div key={`code-prize-${prize.phone}-${index.toString()}`} className="m-prizeLabel_value">
            <Typography.Text extendClasses="m-prizeLabel_value-text">{prize.phone}</Typography.Text>
          </div>
        ))
      }
    </div>
  </div>
);

export default PrizeLabel;
