import React from 'react';
import { Image, Typography } from 'tfc-components';

import logo from 'assets/images/logo-home.svg';
import mapModifiers from 'utils/functions';

interface DrawLayoutProps {
  children?: React.ReactNode;
  isResult?: boolean;
}

const DrawLayout: React.FC<DrawLayoutProps> = ({ children, isResult }) => (
  <div className={mapModifiers('t-drawLayout', isResult ? 'isResult' : '')}>
    <div className="t-drawLayout_logo">
      <Image imgSrc={logo} alt="Logo Electrolux" ratio={187 / 48} />
    </div>
    <Typography.Text extendClasses="t-drawLayout_title" fontweight="700" textStyle="center">QUAY SỐ MAY MẮN TRÚNG THƯỞNG</Typography.Text>
    <main className="t-drawLayout_content">
      {children}
    </main>
  </div>
);

export default DrawLayout;
