import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface CustomerInfoFormTypes {
  fullName: string;
  phone: string;
  address?: string;
  email?: string;
  provinceCode: OptionType;
  districtCode: OptionType;
  wardCode?: OptionType;
  agree: boolean;
}
interface ProductInfo {
  productId: OptionType;
  modelId: OptionType;
  seriesNumber: string;
}

export interface PurchaseInfoForm {
  buyDate: string;
  shopId: OptionType;
  shopName?: string;
  products: ProductInfo[];
  invoiceImages?: File[];
}
interface InformationState {
  customerInfo?: CustomerInfoFormTypes;
  purchaseInfo?: PurchaseInfoForm;
}

const initialState: InformationState = {
  customerInfo: undefined,
  purchaseInfo: undefined,
};

export const informationSlice = createSlice({
  name: 'informationReducer',
  initialState,
  reducers: {
    saveCustomerInfo: ($state, action: PayloadAction<CustomerInfoFormTypes>) => {
      $state.customerInfo = action.payload;
    },
    savePurchaseInfo: ($state, action: PayloadAction<PurchaseInfoForm>) => {
      $state.purchaseInfo = action.payload;
    },
    resetData: () => initialState,
  },
  extraReducers() { },
});

export const { saveCustomerInfo, savePurchaseInfo, resetData } = informationSlice.actions;

export default informationSlice.reducer;
