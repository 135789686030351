import {
  LuckyDrawMatchDataTypes, LuckyDrawMatchSummaryDataTypes,
  LuckyDrawProfileTypes, UpdateLuckyDrawProfileTypes
} from './types';

import axiosInstance from 'services/common/instance';

export const getLuckyDrawMatch1stService = async (
  phase: number
): Promise<LuckyDrawMatchDataTypes> => {
  const response = await axiosInstance.post('lucky-draw-matches/1st', { phase });
  return response.data.data;
};

export const getLuckyDrawMatch2ndService = async (
  phase: number
): Promise<LuckyDrawMatchDataTypes> => {
  const response = await axiosInstance.post('lucky-draw-matches/2nd', { phase });
  return response.data.data;
};

export const getLuckyDrawMatch3rdService = async (
  phase: number
): Promise<LuckyDrawMatchDataTypes> => {
  const response = await axiosInstance.post('lucky-draw-matches/3rd', { phase });
  return response.data.data;
};

export const getLuckyDrawMatch4thService = async (
  phase: number
): Promise<LuckyDrawMatchDataTypes> => {
  const response = await axiosInstance.post('lucky-draw-matches/4th', { phase });
  return response.data.data;
};

export const getLuckyDrawMatchSummaryService = async ()
  : Promise<LuckyDrawMatchSummaryDataTypes> => {
  const response = await axiosInstance.get('lucky-draw-matches/summary');
  return response.data.data;
};

export const getLuckyDrawProfileService = async (
  accessKey: string
): Promise<LuckyDrawProfileTypes> => {
  const response = await axiosInstance.get(`lucky-draw-profiles/${accessKey}`);
  return response.data.data;
};

export const updateLuckyDrawProfileService = async (
  accessKey: string,
  data: UpdateLuckyDrawProfileTypes
): Promise<void> => {
  await axiosInstance.post(`lucky-draw-profiles/${accessKey}`, data);
};
