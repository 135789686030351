import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getLuckyDrawMatchSummaryService } from 'services/luckyDrawMatch';
import { LuckyDrawMatchSummaryDataTypes } from 'services/luckyDrawMatch/types';

interface PrizesState {
  loading?: boolean;
  summary?: LuckyDrawMatchSummaryDataTypes;
}

const initialState: PrizesState = {
};

export const getPrizesAsync = createAsyncThunk<LuckyDrawMatchSummaryDataTypes>(
  'prizesReducer/getPrizesSummary',
  async (_, { rejectWithValue }) => {
    try {
      return await getLuckyDrawMatchSummaryService();
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const prizesSlice = createSlice({
  name: 'prizesReducer',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getPrizesAsync.pending, ($state) => {
      $state.loading = true;
    });
    builder.addCase(getPrizesAsync.fulfilled, ($state, action) => {
      $state.summary = action.payload;
      $state.loading = false;
    });
    builder.addCase(getPrizesAsync.rejected, ($state) => {
      $state.loading = false;
    });
  },
});

export default prizesSlice.reducer;
