import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useMemo, useState } from 'react';
import { Container } from 'react-bootstrap';
import {
  Controller, FormProvider, useForm, useWatch
} from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Button, Image, Input, Radio, Typography
} from 'tfc-components';

import editIcon from 'assets/icons/ic_edit.svg';
import electrolux from 'assets/images/logoElectrolux.png';
import Pulldown from 'components/molecules/Pulldown';
import UploadPhoto from 'components/molecules/UploadPhoto';
import FormField from 'components/organisms/FormField';
import CustomModal from 'components/organisms/Modal';
import PageLayout from 'components/organisms/PageLayout';
import useDidMount from 'hooks/useDidMount';
import { getLuckyDrawProfileService, updateLuckyDrawProfileService } from 'services/luckyDrawMatch';
import { ChunkImage, UpdateLuckyDrawProfileTypes } from 'services/luckyDrawMatch/types';
import getAllShopsService from 'services/shops';
import { ShopData } from 'services/shops/types';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getSystemAsync } from 'store/systems';
import { ROUTES_PATH } from 'utils/constants';
import mapModifiers, { formatPhoneVietnamese } from 'utils/functions';
import useSchema from 'utils/schemas';

export interface CongratulationFormTypes {
  fullName: string;
  phone: string;
  buyAgency: OptionType;
  addressReceiveGift: string;
  senderAddress: string;
  identityCard: string;
  idType: string;
  buyAgencyOrder?: string;
  beforeIdentityCard?: UploadFileType;
  afterIdentityCard?: UploadFileType;
  passportCard?: UploadFileType;
  smsCode: UploadFileType;
  smsNotifyLuckyDraw: UploadFileType;
  codeSerial: UploadFileType;
  customerWithProduct: UploadFileType;
}

const renderPrize = (prize?: PrizeValueType) => {
  switch (prize) {
    case '1st':
      return ['Giải Nhất', '01 XE MÁY SH 125i'];
    case '2nd':
      return ['Giải Nhì', '01 Iphone 15 Pro'];
    case '3rd':
      return ['Giải Ba', '01 Máy lọc không khí'];
    case '4th':
      return ['Giải Tư', 'Thẻ điện thoại trị giá 300k'];
    default:
      return [];
  }
};

const Congratulation: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { system, loading } = useAppSelector((state) => state.systems);
  const { congratulation } = useSchema();
  const { slug } = useParams();
  const method = useForm<CongratulationFormTypes>({
    resolver: yupResolver(congratulation),
    mode: 'all',
    defaultValues: {
      idType: 'identity',
    }
  });
  const [isConfirm, setIsConfirm] = useState(false);
  const [done, setDone] = useState(false);
  const [err, setErr] = useState<string>();
  const [isNotFound, setIsNotFound] = useState(false);

  /* Queries */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: profile, isLoading } = useQuery('luckyDrawProfile', () => (slug ? getLuckyDrawProfileService(slug) : undefined), {
    onError: () => {
      setIsNotFound(true);
    }
  });
  const { data: shops, isLoading: isLoadingShops } = useQuery<ShopData[]>(['getShops'], getAllShopsService);
  const { mutate: mutateUpdateInfo, isLoading: submitLoading } = useMutation('submitCustomerInfo', (data: {
    accessKey: string,
    data: UpdateLuckyDrawProfileTypes
  }) => updateLuckyDrawProfileService(data.accessKey, data.data), {
    onSuccess: () => {
      setDone(true);
    },
    onError(errors: any) {
      if (errors.length > 0) {
        errors.forEach((error: ValidateError) => {
          if (error.code === 'luckyDrawMatchProfileCreated') {
            setErr('Thông tin cho giải thưởng đã được ghi nhận trước đó.');
          } else {
            setErr('Đã có lỗi xảy ra.<br />Vui lòng thực hiện lại');
          }
        });
      } else {
        setErr('Đã có lỗi xảy ra.<br />Vui lòng thực hiện lại');
      }
    },
  });
  const shopOptions = useMemo(() => shops?.map((shop) => (
    { label: shop.shopData.name, value: shop.shopData.id })) || [], [shops]);
  const documentType = [
    { value: 'identity', label: 'Ảnh CMND/CCCD' },
    { value: 'passport', label: 'Ảnh Hộ chiếu' },
  ];

  const selectedType = useWatch({ name: 'idType', control: method.control, defaultValue: 'identity' });
  const storeId = useWatch({ control: method.control, name: 'buyAgency' });
  const returnChunkValue = (value?: UploadFileType): ChunkImage | undefined => (value ? ({
    chunkedUuid: value.chunkedUuid || '',
    fileExtension: value.file?.name.split('.').pop() || ''
  }) : undefined);
  const onHandleSubmit = (data: CongratulationFormTypes) => {
    if (!slug) {
      return;
    }
    const dataSubmit: UpdateLuckyDrawProfileTypes = {
      fullName: data.fullName,
      phone: data.phone,
      shopId: data.buyAgency.value === 'other' ? undefined : data.buyAgency.value,
      shopName: data.buyAgency.value === 'other' ? data.buyAgencyOrder : data.buyAgency.label,
      idNumber: data.identityCard,
      idNumberType: data.idType === 'identity' ? 'id' : 'passport',
      receiverAddress: data.addressReceiveGift,
      senderAddress: data.senderAddress,
      idFrontImage: data.idType === 'identity' ? returnChunkValue(data.beforeIdentityCard) : undefined,
      idBackImage: data.idType === 'identity' ? returnChunkValue(data.afterIdentityCard) : undefined,
      passportImage: data.idType === 'passport' ? returnChunkValue(data.passportCard) : undefined,
      smsLuckyCodeImage: returnChunkValue(data.smsCode),
      smsMatchImage: returnChunkValue(data.smsNotifyLuckyDraw),
      productSeriesImage: returnChunkValue(data.codeSerial),
      customerWithProductImage: returnChunkValue(data.customerWithProduct),
    };
    mutateUpdateInfo({ accessKey: slug, data: dataSubmit });
  };

  useDidMount(() => {
    dispatch(getSystemAsync());
  });

  useEffect(() => {
    if (profile) {
      method.reset({
        phone: profile?.bill?.phone ? formatPhoneVietnamese(profile?.bill?.phone) : '',
        buyAgency: profile?.bill?.shopId
          ? { label: profile?.bill?.shopName, value: profile?.bill?.shopId } : { label: 'Khác', value: 'other' },
        buyAgencyOrder: profile?.bill?.shopId ? undefined : profile?.bill?.shopName,
        idType: 'identity',
      });
    }
  }, [profile, method]);

  if (loading) {
    return null;
  }

  if (!system?.canAccessLDMLink || isNotFound) {
    return (
      <PageLayout
        loading={isLoading || submitLoading}
        footer={(
          <Button
            extendClasses="p-congratulation_btn btn-status"
            type="submit"
            loading={submitLoading}
            onClick={() => navigate(ROUTES_PATH.HOME)}
          >
            Trở về trang chủ
          </Button>
        )}
      >
        <div className="p-congratulation_thankPage p-congratulation_thankPage_p36">
          <div className="p-congratulation_logo">
            <Image
              imgSrc={electrolux}
              ratio={122 / 32}
              alt="electrolux"
            />
          </div>
          <div className="p-congratulation_messageWrapper">
            <Typography.Text
              textStyle="center"
              extendClasses="color-oxfordBlue fs-16x24"
              fontweight="600"
            >
              Thời gian tham gia chương trình đã hết
            </Typography.Text>
          </div>
          <div className="p-congratulation_descriptionWrapper">
            <Typography.Text
              textStyle="center"
              extendClasses="color-oxfordBlue fs-14x21"
              fontweight="400"
            >
              Hãy liên hệ Hotline 1900633067 (1000đ/phút)
              để được hỗ trợ.
            </Typography.Text>
          </div>
          <div className="p-congratulation_thankWrapper">
            <Typography.Text
              textStyle="center"
              extendClasses="color-oxfordBlue fs-16x24"
              fontweight="600"
            >
              Electrolux xin trân trọng cảm ơn.
            </Typography.Text>
          </div>
        </div>
      </PageLayout>
    );
  }

  if (profile?.profile) {
    return (
      <PageLayout
        loading={isLoading || submitLoading}
        footer={(
          <Button
            extendClasses="p-congratulation_btn btn-status"
            type="submit"
            loading={submitLoading}
            onClick={() => navigate(ROUTES_PATH.HOME)}
          >
            Trở về trang chủ
          </Button>
        )}
      >
        <div className="p-congratulation_thankPage p-congratulation_thankPage_p18">
          <div className="p-congratulation_logo">
            <Image
              imgSrc={electrolux}
              ratio={122 / 32}
              alt="electrolux"
            />
          </div>
          <div className="p-congratulation_messageWrapper">
            <Typography.Text
              textStyle="center"
              extendClasses="color-oxfordBlue fs-16x24 p-congratulation_message"
              fontweight="600"
            >
              Hệ thống đã ghi lại thông tin của bạn
            </Typography.Text>
          </div>
          <div className="p-congratulation_descriptionWrapper">
            <Typography.Text
              textStyle="center"
              extendClasses="color-oxfordBlue fs-14x21"
              fontweight="400"
            >
              Hãy liên hệ với số Hotline 1900633067 (1000đ/phút)
              để được hỗ trợ.
            </Typography.Text>
          </div>
          <div className="p-congratulation_thankWrapper">
            <Typography.Text
              textStyle="center"
              extendClasses="color-oxfordBlue fs-16x24"
              fontweight="600"
            >
              Electrolux xin trân trọng cảm ơn.
            </Typography.Text>
          </div>
        </div>
      </PageLayout>
    );
  }

  if (done) {
    return (
      <PageLayout
        loading={isLoading || submitLoading}
        footer={(
          <Button
            extendClasses="p-congratulation_btn btn-status"
            type="submit"
            loading={submitLoading}
            onClick={() => navigate(ROUTES_PATH.HOME)}
          >
            Trở về trang chủ
          </Button>
        )}
      >
        <div className="p-congratulation_thankPage p-congratulation_thankPage_p12">
          <div className="p-congratulation_logo">
            <Image
              imgSrc={electrolux}
              ratio={122 / 32}
              alt="electrolux"
            />
          </div>
          <div className="p-congratulation_messageWrapper">
            <Typography.Text
              textStyle="center"
              extendClasses="color-oxfordBlue fs-16x24 p-congratulation_message"
              fontweight="600"
            >
              Cảm ơn bạn
            </Typography.Text>
            <Typography.Text
              textStyle="center"
              extendClasses="color-oxfordBlue fs-16x24 p-congratulation_message"
              fontweight="600"
            >
              {profile?.bill.fullName || ''}
            </Typography.Text>
          </div>
          <div className="p-congratulation_descriptionWrapper">
            <Typography.Text
              textStyle="center"
              extendClasses="color-oxfordBlue fs-14x21"
              fontweight="400"
            >
              Electrolux đã nhận được đầy đủ thông tin nhận thưởng.
              Cảm ơn bạn đã dành thời gian cung cấp thông tin.
            </Typography.Text>
          </div>
          <div className="p-congratulation_feedback">
            <Typography.Text
              textStyle="center"
              extendClasses="color-oxfordBlue fs-14x21"
              fontweight="400"
            >
              Chúng tôi sẽ kiểm tra thông tin và thông báo
              đến bạn trong thời gian sớm nhất (trong vòng 15 ngày).
            </Typography.Text>
          </div>
          <div className="p-congratulation_thankWrapper">
            <Typography.Text
              textStyle="center"
              extendClasses="color-oxfordBlue fs-16x24"
              fontweight="600"
            >
              Chúc bạn một ngày tốt lành!
            </Typography.Text>
          </div>
          <div className="p-congratulation_thankyou">
            <Typography.Text
              textStyle="center"
              extendClasses="color-oxfordBlue fs-16x24"
              fontweight="600"
            >
              Electrolux xin trân trọng cảm ơn.
            </Typography.Text>
          </div>
        </div>
      </PageLayout>
    );
  }

  return (
    <PageLayout
      loading={isLoading || submitLoading}
      footer={(
        <Button
          extendClasses="p-congratulation_btn btn-status"
          type="submit"
          loading={submitLoading}
          onClick={() => setIsConfirm(true)}
        >
          Hoàn tất
        </Button>
      )}
    >
      <div className="p-congratulation">
        <FormProvider {...method}>
          <Container>
            <Typography.Heading type="h2" fontweight="600" extendClasses="p-congratulation_title">XIN CHÚC MỪNG!</Typography.Heading>
            <Typography.Text fontweight="600" extendClasses="p-congratulation_noticePrize">
              Bạn đã trúng
              {' '}
              {renderPrize(profile?.mainData.prize)[0]}
              <br />
              {renderPrize(profile?.mainData.prize)[1]}
            </Typography.Text>
            <Typography.Text extendClasses="p-congratulation_noticeInfo" fontweight="400">
              Vui lòng nhập đầy đủ thông tin cá nhân
              để tiến hành nhận thưởng
            </Typography.Text>
            <section className="p-congratulation_form">
              <FormField label="Họ và tên" className="u-mb-12">
                <Controller
                  name="fullName"
                  render={({ field, fieldState: { error } }) => (
                    <>
                      <Input
                        {...field}
                        bordered
                        type="text"
                        placeholder="Nhập họ và tên"
                        extendClasses={mapModifiers('p-congratulation_field', error && 'error')}
                      />
                      {error && (
                        <div className="form-error-message">{error.message}</div>
                      )}
                      <Typography.Text
                        extendClasses="p-congratulation_notice"
                        fontweight="400"
                        textStyle="italic"
                      >
                        Họ và Tên cần khớp với thông tin trên CMND/CCCD/Hộ chiếu
                      </Typography.Text>
                    </>
                  )}
                />
              </FormField>
              <FormField label="Số điện thoại" className="u-mb-12">
                <Controller
                  name="phone"
                  render={({ field, fieldState: { error } }) => (
                    <>
                      <Input
                        {...field}
                        bordered
                        type="text"
                        placeholder="0912321654"
                        extendClasses={mapModifiers('p-congratulation_field', error && 'error')}
                        readOnly={!!profile?.bill?.phone}
                      />
                      {error && (
                        <div className="form-error-message">{error.message}</div>
                      )}
                    </>
                  )}
                />
              </FormField>
              <FormField label="Đại lý mua hàng" className="u-mb-12">
                <Controller
                  name="buyAgency"
                  control={method.control}
                  render={({ field, fieldState: { error } }) => (
                    <>
                      <Pulldown
                        {...field}
                        placeholder="Chọn đại lý"
                        name="buyAgency"
                        handleChange={field.onChange}
                        options={[...shopOptions, { label: 'Khác', value: 'other' }]}
                        loading={isLoadingShops}
                        error={!!error?.message}
                        isDisabled
                      />
                      {error && (
                        <div className="form-error-message">{error.message}</div>
                      )}
                    </>
                  )}
                />
                {storeId?.value === 'other' && (
                  <Controller
                    name="buyAgencyOrder"
                    control={method.control}
                    render={({ field, fieldState: { error } }) => (
                      <>
                        <Input
                          {...field}
                          name="buyAgencyOrder"
                          bordered
                          extendClasses="custom-input p-purchaseInfo_info_inputmore"
                          placeholder="Nhập đại lý mua hàng"
                          readOnly
                          onChange={field.onChange}
                        />
                        {error && (
                          <div className="form-error-message">{error.message}</div>
                        )}
                      </>
                    )}
                  />
                )}
              </FormField>
              <FormField label="Địa chỉ nhận quà" className="u-mb-12">
                <Controller
                  name="addressReceiveGift"
                  render={({ field, fieldState: { error } }) => (
                    <>
                      <Input
                        {...field}
                        bordered
                        type="text"
                        placeholder="Nhập thông tin"
                        extendClasses={mapModifiers('p-congratulation_field', error && 'error')}
                        inputSurfix={(
                          <div style={{
                            width: '100%',
                            maxWidth: '16px'
                          }}
                          >
                            <Image
                              imgSrc={editIcon}
                              ratio={1 / 1}
                              alt="editIcon"
                            />
                          </div>
                        )}
                      />
                      {error && (
                        <div className="form-error-message">{error.message}</div>
                      )}
                      <Typography.Text
                        extendClasses="p-congratulation_notice"
                        fontweight="400"
                        textStyle="italic"
                      >
                        Khuyến khích trùng với địa chỉ đặt sản phẩm
                      </Typography.Text>
                    </>
                  )}
                />
              </FormField>
              <FormField label="Địa chỉ nơi đặt sản phẩm" className="u-mb-12">
                <Controller
                  name="senderAddress"
                  render={({ field, fieldState: { error } }) => (
                    <>
                      <Input
                        {...field}
                        bordered
                        type="text"
                        placeholder="Nhập thông tin"
                        extendClasses={mapModifiers('p-congratulation_field', error && 'error')}
                        inputSurfix={(
                          <div style={{
                            width: '100%',
                            maxWidth: '16px'
                          }}
                          >
                            <Image
                              imgSrc={editIcon}
                              ratio={1 / 1}
                              alt="editIcon"
                            />
                          </div>
                        )}
                      />
                      {error && (
                        <div className="form-error-message">{error.message}</div>
                      )}
                    </>
                  )}
                />
              </FormField>
              <FormField label="Số CMND/CCCD/Hộ chiếu" className="u-mb-12">
                <Controller
                  name="identityCard"
                  render={({ field, fieldState: { error } }) => (
                    <>
                      <Input
                        {...field}
                        bordered
                        type="text"
                        placeholder="Nhập số CMND/CCCD/Hộ chiếu"
                        extendClasses={mapModifiers('p-congratulation_field', error && 'error')}
                      />
                      {error && (
                        <div className="form-error-message">{error.message}</div>
                      )}
                    </>
                  )}
                />
              </FormField>
              <div onClick={() => window.open('/huong_dan_hoan_thien_ho_so.pdf', '_blank')}>
                <Typography.Text
                  textStyle="underline"
                  extendClasses="p-congratulation_guide"
                  fontweight="600"
                >
                  Hướng dẫn chụp ảnh đúng quy định nhận giải
                </Typography.Text>
              </div>
              <div className="p-congratulation_radioGroup">
                {documentType.map((item) => (
                  <Controller
                    key={item.value}
                    name="idType"
                    render={({ field }) => (
                      <Radio
                        name="idType"
                        activeColor="#233178"
                        value={item.value}
                        checked={item.value === field.value}
                        onChange={field.onChange}
                      >
                        <Typography.Text
                          extendClasses="p-congratulation_radioText"
                          fontweight="400"
                        >
                          {item.label}
                        </Typography.Text>
                      </Radio>
                    )}
                  />
                ))}
              </div>
              {selectedType === 'identity' ? (
                <FormField label="Ảnh CMND/CCCD (còn hiệu lực)" className="u-mb-12">
                  <div className="p-congratulation_flex">
                    <div className="p-congratulation_wrapCard">
                      <Controller
                        name="beforeIdentityCard"
                        render={({ field, fieldState: { error } }) => (
                          <>
                            <UploadPhoto
                              description="Chụp/tải ảnh"
                              title="Mặt trước"
                              handleChooseFile={field.onChange}
                              handleRemoveFile={() => field.onChange(null)}
                              value={field.value}
                              accepts=".png,.jpg,.gif,.jpeg"
                            />
                            {error && (
                              <div className="p-congratulation-error">{(error as any).file?.message}</div>
                            )}
                          </>
                        )}
                      />
                    </div>
                    <div className="p-congratulation_wrapCard">
                      <Controller
                        name="afterIdentityCard"
                        render={({ field, fieldState: { error } }) => (
                          <>
                            <UploadPhoto
                              description="Chụp/tải ảnh"
                              title="Mặt sau"
                              handleChooseFile={field.onChange}
                              handleRemoveFile={() => field.onChange(undefined)}
                              value={field.value}
                              accepts=".png,.jpg,.gif,.jpeg"
                            />
                            {error && (
                              <div className="p-congratulation-error">{(error as any).file?.message}</div>
                            )}
                          </>
                        )}
                      />
                    </div>
                  </div>
                </FormField>
              ) : (
                <FormField label="Ảnh Hộ chiếu (còn hiệu lực)" className="u-mb-12">
                  <div className="p-congratulation_flex">
                    <div className="p-congratulation_wrapCard">
                      <Controller
                        name="passportCard"
                        render={({ field, fieldState: { error } }) => (
                          <>
                            <UploadPhoto
                              description="Chụp/tải ảnh"
                              handleChooseFile={field.onChange}
                              handleRemoveFile={() => field.onChange(null)}
                              value={field.value}
                              accepts=".png,.jpg,.gif,.jpeg"
                            />
                            {error && (
                              <div className="p-congratulation-error">{(error as any).file?.message}</div>
                            )}
                          </>
                        )}
                      />
                    </div>
                  </div>
                </FormField>
              )}
              <FormField label="Ảnh tin nhắn mã dự thưởng" className="u-mb-12">
                <div className="p-congratulation_flex">
                  <div className="p-congratulation_wrapCard">
                    <Controller
                      name="smsCode"
                      render={({ field, fieldState: { error } }) => (
                        <>
                          <UploadPhoto
                            description="Chụp/tải ảnh"
                            handleChooseFile={field.onChange}
                            handleRemoveFile={() => field.onChange(null)}
                            value={field.value}
                            accepts=".png,.jpg,.gif,.jpeg"
                          />
                          {error && (
                            <div className="p-congratulation-error">{(error as any).file?.message}</div>
                          )}
                        </>
                      )}
                    />
                  </div>
                </div>
              </FormField>
              <FormField label="Ảnh tin nhắn thông báo trúng thưởng" className="u-mb-12">
                <div className="p-congratulation_flex">
                  <div className="p-congratulation_wrapCard">
                    <Controller
                      name="smsNotifyLuckyDraw"
                      render={({ field, fieldState: { error } }) => (
                        <>
                          <UploadPhoto
                            description="Chụp/tải ảnh"
                            handleChooseFile={field.onChange}
                            handleRemoveFile={() => field.onChange(null)}
                            value={field.value}
                            accepts=".png,.jpg,.gif,.jpeg"
                          />
                          {error && (
                            <div className="p-congratulation-error">{(error as any).file?.message}</div>
                          )}
                        </>
                      )}
                    />
                  </div>
                </div>
              </FormField>
              <FormField label="Ảnh mã số sản phẩm (số serial)" className="u-mb-12">
                <div className="p-congratulation_flex">
                  <div className="p-congratulation_wrapCard">
                    <Controller
                      name="codeSerial"
                      render={({ field, fieldState: { error } }) => (
                        <>
                          <UploadPhoto
                            description="Chụp/tải ảnh"
                            handleChooseFile={field.onChange}
                            handleRemoveFile={() => field.onChange(null)}
                            value={field.value}
                            accepts=".png,.jpg,.gif,.jpeg"
                          />
                          {error && (
                            <div className="p-congratulation-error">{(error as any).file?.message}</div>
                          )}
                        </>
                      )}
                    />
                  </div>
                </div>
              </FormField>
              <FormField label="Ảnh khách hàng chụp với sản phẩm" className="u-mb-12">
                <div className="p-congratulation_flex">
                  <div className="p-congratulation_wrapCard">
                    <Controller
                      name="customerWithProduct"
                      render={({ field, fieldState: { error } }) => (
                        <>
                          <UploadPhoto
                            description="Chụp/tải ảnh"
                            handleChooseFile={field.onChange}
                            handleRemoveFile={() => field.onChange({})}
                            value={field.value}
                            accepts=".png,.jpg,.gif,.jpeg"
                          />
                          {error && (
                            <div className="p-congratulation-error">{(error as any).file?.message}</div>
                          )}
                        </>
                      )}
                    />
                  </div>
                </div>
              </FormField>
            </section>
          </Container>
        </FormProvider>
      </div>
      <CustomModal
        isOpen={!!err}
        handleClose={() => setErr(undefined)}
      >
        <div
          className="p-customerInfo_popup_error"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: err || '' }}
        />
        <Button
          extendClasses="p-confirmInfo_thank_button"
          onClick={() => setErr(undefined)}
        >
          Đóng
        </Button>
      </CustomModal>
      <CustomModal
        isOpen={isConfirm}
        handleClose={() => setIsConfirm(false)}
      >
        <div className="p-congratulation_modal">
          <Typography.Text fontweight="400" textStyle="center">
            Bạn vui lòng kiểm tra thông tin trước khi gửi.
            <br />
            Bạn chắc chắn muốn hoàn tất Hồ sơ nhận giải?
          </Typography.Text>
          <div className="p-purchaseInfo_modal_buttons">
            <Button
              extendClasses="p-purchaseInfo_modal_button"
              onClick={() => setIsConfirm(false)}
            >
              Không
            </Button>
            <Button
              extendClasses="p-purchaseInfo_modal_button"
              onClick={method.handleSubmit(onHandleSubmit)}
            >
              Có
            </Button>
          </div>
        </div>
      </CustomModal>
    </PageLayout>
  );
};

export default Congratulation;
