import React from 'react';
import { Typography } from 'tfc-components';

import CustomModal from '../Modal';

interface NotifyProps {
  isOpen: boolean;
  errorMessage?: string;
  onHandleClose: () => void;
}

const Notify: React.FC<NotifyProps> = ({
  isOpen, errorMessage, onHandleClose
}) => (
  <CustomModal
    variant="maxWidth600"
    isOpen={isOpen}
    handleClose={onHandleClose}
  >
    <div className="o-notify_popup">
      <Typography.Text textStyle="center" extendClasses="o-notify_popup_text" fontweight="700">
        {errorMessage}
      </Typography.Text>
      <Typography.Text textStyle="center" extendClasses="o-notify_popup_text">
        Xin lỗi vì vấn đề này.
      </Typography.Text>
    </div>
  </CustomModal>
);

export default Notify;
