/* eslint-disable prefer-regex-literals */
import * as yup from 'yup';

import { CongratulationFormTypes } from 'pages/Congratulation';

export const phoneRegExp = /^(\+\d{1,3}[- ]?)?\d{10}$/;

const useSchemas = () => {
  const customerInfo = yup.object().shape({
    fullName: yup.string().required('Vui lòng cung cấp thông tin'),
    phone: yup.string().required('Vui lòng cung cấp thông tin').matches(phoneRegExp, 'Số điện thoại chưa hợp lệ'),
    email: yup.string().email('Email chưa hợp lệ'),
    address: yup.string(),
    provinceCode: yup.object().shape({
      value: yup.string().required(),
      label: yup.string().required()
    }).default(undefined)
      .required('Vui lòng cung cấp thông tin'),
    districtCode: yup.object().shape({
      value: yup.string().required(),
      label: yup.string().required()
    }).default(undefined)
      .required('Vui lòng cung cấp thông tin'),
    agree: yup.bool().default(true).oneOf([true], 'Vui lòng đánh dấu vào ô đồng ý để tiếp tục tham gia chương trình của chúng tôi.'),
  });

  const billInformation = yup.object().shape({
    buyDate: yup.string().required('Vui lòng cung cấp thông tin'),
    shopId: yup.object().shape({
      value: yup.string().required(),
      label: yup.string().required()
    }).default(undefined)
      .required('Vui lòng cung cấp thông tin'),
    shopName: yup.string().when(['shopId'], {
      is: (shopId: OptionType) => shopId?.value === 'other',
      then: (schema) => schema.required('Vui lòng nhập Đại lý'),
    }),
    products: yup.array().min(1).max(5).of(yup.object().shape({
      productId: yup.object().shape({
        value: yup.string().required(),
        label: yup.string().required()
      }).default(undefined)
        .required('Vui lòng cung cấp thông tin'),
      modelId: yup.object().shape({
        value: yup.string().required(),
        label: yup.string().required()
      }).default(undefined)
        .required('Vui lòng cung cấp thông tin'),
      seriesNumber: yup.string().matches(/^\d{8}$/, 'Số serial không đúng định dạng. Giá trị phải có 8 chữ số').required('Vui lòng cung cấp thông tin')
    }))
      .required('Vui lòng cung cấp thông tin')
  });

  const congratulation: yup.ObjectSchema<CongratulationFormTypes> = yup.object().shape({
    fullName: yup.string().required('Vui lòng cung cấp thông tin'),
    phone: yup.string().required('Vui lòng cung cấp thông tin').matches(phoneRegExp, 'Số điện thoại chưa hợp lệ'),
    buyAgency: yup.object().shape({
      value: yup.string().required(),
      label: yup.string().required()
    }).default(undefined)
      .required('Vui lòng cung cấp thông tin'),
    idType: yup.string().required('Vui lòng cung cấp thông tin'),
    identityCard: yup.string()
      .required('Vui lòng cung cấp thông tin').when(['idType'], {
        is: (idType: string) => idType === 'identity',
        then: (schema) => schema.min(9, 'Số CMND/CCCD phải có ít nhất 9 ký tự')
          .max(12, 'Số CMND/CCCD tối đa 12 ký tự').matches(/^[0-9]*$/, 'Số CMND/CCCD phải là ký tự số'),
        otherwise: (schema) => schema.min(8, 'Số hộ chiếu phải có ít nhất 8 ký tự')
      }),
    addressReceiveGift: yup.string().required('Vui lòng cung cấp thông tin'),
    senderAddress: yup.string().required('Vui lòng cung cấp thông tin'),
    buyAgencyOrder: yup.string()
      .when(['buyAgency'], {
        is: (buyAgency: OptionType) => buyAgency?.value === 'other',
        then: (schema) => schema.required('Vui lòng cung cấp thông tin'),
      }),
    beforeIdentityCard: yup.object().shape({
      file: yup.mixed<File>(),
      chunkedUuid: yup.string(),
    }).when(['idType'], {
      is: (idType: string) => idType === 'identity',
      then: (schema) => schema.shape({
        file: yup.mixed<File>().required('Cập nhật hình ảnh'),
        chunkedUuid: yup.string().required('Cập nhật hình ảnh'),
      }),
    }),
    afterIdentityCard: yup.object().shape({
      file: yup.mixed<File>(),
      chunkedUuid: yup.string(),
    }).when(['idType'], {
      is: (idType: string) => idType === 'identity',
      then: (schema) => schema.shape({
        file: yup.mixed<File>().required('Cập nhật hình ảnh'),
        chunkedUuid: yup.string().required('Cập nhật hình ảnh'),
      }),
    }),
    passportCard: yup.object().shape({
      file: yup.mixed<File>(),
      chunkedUuid: yup.string(),
    }).when(['idType'], {
      is: (idType: string) => idType === 'passport',
      then: (schema) => schema.shape({
        file: yup.mixed<File>().required('Cập nhật hình ảnh'),
        chunkedUuid: yup.string().required('Cập nhật hình ảnh'),
      }),
    }),
    smsCode: yup.object().shape({
      file: yup.mixed<File>().required('Cập nhật hình ảnh'),
      chunkedUuid: yup.string().required('Cập nhật hình ảnh'),
    }).required('Cập nhật hình ảnh'),
    smsNotifyLuckyDraw: yup.object().shape({
      file: yup.mixed<File>().required('Cập nhật hình ảnh'),
      chunkedUuid: yup.string().required('Cập nhật hình ảnh'),
    }).required('Cập nhật hình ảnh'),
    codeSerial: yup.object().shape({
      file: yup.mixed<File>().required('Cập nhật hình ảnh'),
      chunkedUuid: yup.string().required('Cập nhật hình ảnh'),
    }).required('Cập nhật hình ảnh'),
    customerWithProduct: yup.object().shape({
      file: yup.mixed<File>().required('Cập nhật hình ảnh'),
      chunkedUuid: yup.string().required('Cập nhật hình ảnh'),
    }).required('Cập nhật hình ảnh'),
  });

  return {
    customerInfo,
    billInformation,
    congratulation,
  };
};

export default useSchemas;
