import { GeneralDataTypes } from './types';

import axiosInstance from 'services/common/instance';

const getSystemService = async (): Promise<GeneralDataTypes> => {
  const res = await axiosInstance.get('systems/general');
  return res.data.data;
};

export default getSystemService;
