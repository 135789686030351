/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { Typography } from 'tfc-components';

import CustomModal from 'components/organisms/Modal';
import useDidMount from 'hooks/useDidMount';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getSystemAsync } from 'store/systems';
import { LOCALSTORAGE_KEYS, ROUTES_PATH } from 'utils/constants';

// const navigate = useNavigate();
// useDidMount(() => {
//   const uuid = localStorage.getItem(LOCALSTORAGE_KEYS.ELECTROLUX_UUID);
//   const currStep = localStorage.getItem(LOCALSTORAGE_KEYS.ELECTROLUX_STEP);
//   if (!uuid) {
//     navigate(ROUTES_PATH.HOME);
//   }
//   if (currStep) {
//     switch (Number(currStep)) {
//       case 1:
//       case 2:
//         navigate(ROUTES_PATH.CUSTOMER_INFORMATION);
//         break;
//       case 3:
//         navigate(ROUTES_PATH.PURCHASE_INFORMATION);
//         break;
//       case 4:
//       case 5:
//         navigate(ROUTES_PATH.CONFIRM_INFORMATION);
//         break;
//       default:
//         break;
//     }
//   }
// });
const MainLayout: React.FC = () => {
  const systemData = useAppSelector((state) => state.systems.system);

  return (
    <main>
      <Outlet />
      <CustomModal
        variant="maxWidth600"
        isOpen={!!systemData && systemData.eventEnded}
      >
        <div className="o-notify_popup">
          <Typography.Text textStyle="center" extendClasses="o-notify_popup_text" fontweight="700">
            Chương trình &quot;3345 Quà Lớn Tri Ân&quot;
            <br />
            của Electrolux đã ngưng nhận lượt
            <br />
            đăng ký tham gia.
            Cảm ơn bạn đã quan tâm và Chúc bạn năm mới an lành!
          </Typography.Text>
        </div>
      </CustomModal>
    </main>
  );
};
export default MainLayout;
