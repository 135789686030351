import React, { useEffect, useMemo, useRef } from 'react';
import { Control, Controller, useWatch } from 'react-hook-form';
import { useQuery } from 'react-query';
import Select from 'react-select/dist/declarations/src/Select';
import { Button, Input, Typography } from 'tfc-components';

import Pulldown from 'components/molecules/Pulldown';
import FormField from 'components/organisms/FormField';
import CustomModal from 'components/organisms/Modal';
import getAllProductsService from 'services/products';
import { PurchaseInfoForm } from 'store/information';

type Props = {
  nestedIndex: number;
  control: Control<PurchaseInfoForm, any>
  handleRemove?: () => void;
};

const Product: React.FC<Props> = ({ nestedIndex, control, handleRemove }) => {
  const { data: products } = useQuery('products', getAllProductsService);
  const productsWatch = useWatch({ name: 'products', control });
  const productWatch = useWatch({ name: `products.${nestedIndex}.productId`, control });
  const serialWatch = useWatch({ name: `products.${nestedIndex}.seriesNumber`, control });
  const modelWatch = useWatch({ name: `products.${nestedIndex}.modelId`, control });
  const [isOpen, setIsOpen] = React.useState(false);
  const modelRef = useRef<Select>(null);

  const productOptions = useMemo(() => products?.map((val) => ({
    label: val.productData.name,
    value: val.productData.id,
  })) || [], [products]);

  const modelOptions: OptionType[] = useMemo(() => {
    if (!products || !productWatch) {
      return [];
    }
    return products.find(
      (item) => item.productData.id === productWatch.value
    )?.productModel.map((val) => ({ label: val.name, value: val.id })) || [];
  }, [productWatch, products]);

  useEffect(() => {
    if (serialWatch) {
      const findIndex = productsWatch.filter((_, idx) => idx !== nestedIndex).findIndex(
        (item) => item.seriesNumber === serialWatch
      );

      if (findIndex > -1) {
        control.setError(`products.${nestedIndex}.seriesNumber`, { message: 'Số serial không được trùng nhau' });
      }
    }
  }, [control, nestedIndex, productsWatch, serialWatch]);

  return (
    <div className="p-purchaseInfo_product">
      {nestedIndex > 0 && (
        <Button extendClasses="p-purchaseInfo_product_remove" onClick={handleRemove}>
          <Typography.Text textStyle="underline">Xóa sản phẩm</Typography.Text>
        </Button>
      )}
      <Typography.Text extendClasses="p-purchaseInfo_product_title" fontweight="600">
        Sản phẩm
        {' '}
        {nestedIndex + 1}
        :
      </Typography.Text>
      <FormField label="Tên sản phẩm" className="u-mt-12">
        <Controller
          name={`products.${nestedIndex}.productId`}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <>
              <Pulldown
                {...field}
                placeholder="Chọn sản phẩm"
                name={`products.${nestedIndex}.name`}
                handleChange={(option) => {
                  if (modelWatch) {
                    modelRef.current?.clearValue();
                  }
                  field.onChange(option);
                }}
                options={productOptions}
              />
              {error && (
                <div className="form-error-message">{error.message}</div>
              )}
            </>
          )}
        />
      </FormField>
      <FormField label="Model" className="u-mt-12">
        <Controller
          name={`products.${nestedIndex}.modelId`}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <>
              <Pulldown
                {...field}
                ref={modelRef}
                placeholder="Chọn model"
                name={`products.${nestedIndex}.name`}
                handleChange={field.onChange}
                options={modelOptions}
              />
              {error && (
                <div className="form-error-message">{error.message}</div>
              )}
            </>
          )}
        />
      </FormField>
      <FormField label="Số Serial (8 chữ số)" className="u-mt-12">
        <Controller
          name={`products.${nestedIndex}.seriesNumber`}
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <>
              <Input
                bordered
                extendClasses="custom-input"
                value={value}
                placeholder="Nhập số serial"
                onChange={onChange}
              />
              {error && (
                <div className="form-error-message">{error.message}</div>
              )}
            </>
          )}
        />
      </FormField>
      <div
        className="p-purchaseInfo_popup u-mt-8"
        onClick={() => window.open('/huong_dan_tim_so_serial.pdf', '_blank')}
      >
        <Typography.Text
          extendClasses="p-purchaseInfo_popup-title"
          fontweight="600"
          textStyle="underline"
        >
          Cách tìm tên model và số serial trên sản phẩm
        </Typography.Text>
      </div>
      <CustomModal
        isOpen={isOpen}
      >
        <div className="p-purchaseInfo_modal">
          <Typography.Text
            fontweight="600"
            textStyle="center"
            extendClasses="color-oxfordBlue p-purchaseInfo_modal_title"
          >
            Cách tìm tên model và số serial
            <br />
            trên sản phẩm
          </Typography.Text>
          <div className="p-purchaseInfo_modal_content">
            <Typography.Text extendClasses="p-purchaseInfo_modal_text">
              1. Lorem Ipsum
            </Typography.Text>
            <div className="u-mt-4">
              <Typography.Text>
                2. Lorem Ipsum
              </Typography.Text>
            </div>
            <div className="u-mt-4">
              <Typography.Text>
                3. Lorem Ipsum
              </Typography.Text>
            </div>
          </div>
          <Button
            extendClasses="p-purchaseInfo_modal_button"
            onClick={() => setIsOpen(false)}
          >
            Đóng
          </Button>
        </div>
      </CustomModal>
    </div>
  );
};

export default Product;
