import './App.scss';

import React, { Suspense, useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import MainLayout from 'components/templates/MainLayout';
import ConfirmInfo from 'pages/ConfirmInfo';
import Congratulation from 'pages/Congratulation';
import CustomerInfo from 'pages/CustomerInfo';
import Home from 'pages/Home';
import Prize from 'pages/Prize';
import PurchaseInfo from 'pages/PurchaseInfo';
import { store } from 'store';
import { useAppDispatch } from 'store/hooks';
import { getSystemAsync } from 'store/systems';
import { ROUTES_PATH } from 'utils/constants';

const App: React.FC = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getSystemAsync());
  }, [dispatch]);
  return (
    <Suspense fallback>
      <Routes>
        <Route element={<MainLayout />}>
          <Route path={ROUTES_PATH.HOME} element={<Home />} />
          <Route path={ROUTES_PATH.CUSTOMER_INFORMATION} element={<CustomerInfo />} />
          <Route path={ROUTES_PATH.PURCHASE_INFORMATION} element={<PurchaseInfo />} />
          <Route path={ROUTES_PATH.CONFIRM_INFORMATION} element={<ConfirmInfo />} />
        </Route>
        <Route path={`${ROUTES_PATH.CONGRATULATION}/:slug`} element={<Congratulation />} />
        <Route path={`${ROUTES_PATH.PRIZES}/:slug`} element={<Prize />} />
      </Routes>
    </Suspense>
  );
};

const AppProvider: React.FC = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: 0,
        refetchOnMount: true,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      },
    },
  });
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </QueryClientProvider>
    </Provider>
  );
};

export default AppProvider;
