import React from 'react';

import PrizeLabel from 'components/molecules/PrizeLabel';
import mapModifiers from 'utils/functions';

interface FrameResultProps {
  resultPrizes: Array<Array<Prize>>;
  layoutFor?: LayoutFor;
}

const FrameResult: React.FC<FrameResultProps> = ({ resultPrizes, layoutFor }) => (
  <div className={mapModifiers('o-frameResult', layoutFor)}>
    {
      resultPrizes.map((prizes, idx) => (
        <div className="o-frameResult_item" key={`prize-${idx.toString()}`}>
          <PrizeLabel
            prizes={prizes}
            layoutFor={layoutFor}
          />
        </div>
      ))
    }
  </div>
);

export default FrameResult;
