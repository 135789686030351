import React, { useCallback, useEffect } from 'react';
import {
  Button,
  Loading,
  Typography
} from 'tfc-components';

import OtpInput from 'components/organisms/OtpInput';
import useWindowDimensions from 'hooks/useWindowDemensions';
import { COLORS } from 'utils/constants';
import mapModifiers from 'utils/functions';

type Props = {
  phone: string;
  retryIn: number;
  expiredIn: number;
  verifyLoading: boolean;
  error?: string;
  handleVerify: (otp: string) => void;
  handleResend: () => void;
  handleCancel: () => void;
  handleChangeOtp?: (otp: string) => void;
};

const ConfirmOtp: React.FC<Props> = ({
  phone, retryIn, expiredIn, verifyLoading,
  error, handleResend, handleVerify, handleCancel, handleChangeOtp
}) => {
  const [disabledVerify, setDisabledVerify] = React.useState(true);
  const [timeEnabledResend, setTimeEnabledResend] = React.useState(retryIn);
  const [otp, setOtp] = React.useState('');
  const { height } = useWindowDimensions();
  const handleSubmit = useCallback((params: string) => {
    if (params) {
      setOtp(params);
      setDisabledVerify(false);
    } else {
      setDisabledVerify(true);
    }
  }, []);

  const onHandleResendOTP = useCallback(() => {
    if (timeEnabledResend === 0) {
      setTimeEnabledResend(retryIn);
      handleResend();
    }
  }, [handleResend, retryIn, timeEnabledResend]);

  useEffect(() => {
    // Countdown 120 seconds
    let count = timeEnabledResend;
    const timer = setInterval(() => {
      count -= 1;
      setTimeEnabledResend(count);
    }, 1000);
    if (timeEnabledResend === 0) clearInterval(timer);
    return () => {
      clearInterval(timer);
    };
  }, [timeEnabledResend]);

  useEffect(() => {
    setTimeEnabledResend(retryIn);
  }, [retryIn]);

  return (
    <div className="p-confirmOTP" style={{ height: `${height}px` }}>
      <div>
        <Typography.Heading extendClasses="p-confirmOTP_heading" fontweight="600">Xác thực OTP</Typography.Heading>
        <Typography.Text extendClasses="p-confirmOTP_confirmPhone" fontweight="600">
          Mã xác thực đã gửi qua số điện thoại:
          {' '}
          {phone}
        </Typography.Text>
        <div className="p-confirmOTP_enterOTP">
          <OtpInput
            isError={!!error}
            handleSubmit={handleSubmit}
            handleChangeOtp={handleChangeOtp}
          />
          {error && (
            // eslint-disable-next-line react/no-danger
            <div className="p-confirmOTP_error" style={{ fontSize: '14px' }} dangerouslySetInnerHTML={{ __html: error }} />
          )}
        </div>
        <Typography.Text extendClasses="p-confirmOTP_expiredOTP" fontweight="600">
          Mã OTP có hiệu lực trong
          {' '}
          {expiredIn}
          {' '}
          giây.
          {' '}
          <span
            className={mapModifiers('p-confirmOTP_expiredOTP_resend', timeEnabledResend === 0 && 'active')}
            onClick={onHandleResendOTP}
          >
            {timeEnabledResend === 0 ? 'Gửi lại' : `Gửi lại (${timeEnabledResend}s)`}
          </span>
        </Typography.Text>
      </div>
      <div className="p-confirmOTP_btn">
        <div className="p-confirmOTP_btnWrap">
          <Button extendClasses="p-confirmOTP_btnBack" type="button" onClick={handleCancel}>
            Quay về
          </Button>
          <Button
            extendClasses="p-confirmOTP_btnVerify btn-status"
            disabled={disabledVerify}
            loading={verifyLoading}
            loadingIndicator={<Loading.CircleDashed width={20} color={COLORS.white} />}
            onClick={() => handleVerify(otp)}
          >
            Xác thực
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmOtp;
