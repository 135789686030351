import React from 'react';

interface ButtonLuckyDrawProps {
  onHandleDraw: () => void;
  textButton: string;
  disabled?: boolean;
}

const ButtonLuckyDraw: React.FC<ButtonLuckyDrawProps> = ({
  textButton,
  disabled,
  onHandleDraw,
}) => (
  <button type="button" disabled={disabled} onClick={onHandleDraw} className="m-buttonLuckyDraw u-mt-16">
    <p>{textButton}</p>
  </button>
);

export default ButtonLuckyDraw;
