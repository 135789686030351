import React, { useMemo } from 'react';
import { Container } from 'react-bootstrap';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import {
  Button, Image, Loading, Typography
} from 'tfc-components';

import pdf from 'assets/images/pdf.png';
import CustomModal from 'components/organisms/Modal';
import PageLayout from 'components/organisms/PageLayout';
import useDidMount from 'hooks/useDidMount';
import { completeBillService, getSubmitedDataService } from 'services/bills';
import { clearLocalStorage } from 'services/common/storage';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { resetData } from 'store/information';
import { COLORS, LOCALSTORAGE_KEYS, ROUTES_PATH } from 'utils/constants';
import { formatDateDDMMYYYY, formatPhoneNumber } from 'utils/functions';

const profileField = ['Họ và tên', 'Số điện thoại', 'Email', 'Địa chỉ cư trú', 'Phường/xã', 'Quận/huyện', 'Tỉnh/thành'];
const purchaseField = ['Ngày mua', 'Đại lý mua hàng'];
const productField = ['Tên sản phẩm', 'Model', 'Số Serial'];

type ProfileType = {
  name: string;
  phone: string;
  email: string;
  address: string;
  ward: string;
  district: string;
  province: string;
};

type PurchaseType = {
  date: string;
  agencyName: string;
};

type ProductType = {
  name: string;
  model: string;
  serial: string;
};

const ConfirmInfo: React.FC = () => {
  /* Hooks */
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { customerInfo, purchaseInfo } = useAppSelector((state) => state.information);

  /* States */
  const [isOpen, setIsOpen] = React.useState(false);
  const [globalError, setGlobalError] = React.useState({
    isBackHome: false,
    message: ''
  });

  /* Queries */
  const { data: submittedData, isLoading: submittedDataLoading } = useQuery('submittedData', () => {
    const uuid = localStorage.getItem(LOCALSTORAGE_KEYS.ELECTROLUX_UUID);
    if (uuid) {
      return getSubmitedDataService(uuid);
    }
    return undefined;
  }, {
    enabled: !!localStorage.getItem(LOCALSTORAGE_KEYS.ELECTROLUX_UUID)
      || !customerInfo || !purchaseInfo,
  });

  const { mutate: completeBillMutate, isLoading } = useMutation('completeBill', completeBillService, {
    onSuccess: () => {
      setIsOpen(true);
      localStorage.setItem(LOCALSTORAGE_KEYS.ELECTROLUX_STEP, '5');
    },
    onError(error: any) {
      if (error.length > 0) {
        const err = error[0];
        if (err.field === 'uuid') {
          setGlobalError({
            isBackHome: true,
            message: 'Phiên đăng ký của bạn đã hết hạn.<br />Vui lòng thực hiện lại'
          });
        }
      } else {
        setGlobalError({
          isBackHome: false,
          message: 'Đã có lỗi xảy ra.<br />Vui lòng thực hiện lại'
        });
      }
    },
  });

  /* Memo Data */
  const profile: ProfileType = useMemo(() => (
    {
      name: customerInfo?.fullName || submittedData?.generalInformation?.fullName || '',
      phone: customerInfo?.phone || formatPhoneNumber(submittedData?.generalInformation?.phone) || '',
      email: customerInfo?.email || submittedData?.generalInformation?.email || '',
      address: customerInfo?.address || submittedData?.generalInformation?.address || '',
      ward: customerInfo?.wardCode?.label || submittedData?.generalInformation?.wardName || '',
      district: customerInfo?.districtCode.label || submittedData?.generalInformation?.districtName || '',
      province: customerInfo?.provinceCode.label || submittedData?.generalInformation?.provinceName || '',
    }
  ), [customerInfo, submittedData]);

  const purchase: PurchaseType = useMemo(() => ({
    // eslint-disable-next-line no-nested-ternary
    date: purchaseInfo?.buyDate ? formatDateDDMMYYYY(purchaseInfo.buyDate)
      : submittedData?.billInformation?.buyDate ? formatDateDDMMYYYY(submittedData.billInformation.buyDate) : '',
    // eslint-disable-next-line no-nested-ternary
    agencyName: purchaseInfo?.shopId ? purchaseInfo.shopId.value !== 'other'
      ? purchaseInfo.shopId.label : purchaseInfo.shopName
      || submittedData?.billInformation?.shopName || '' : '',
  }), [purchaseInfo, submittedData]);

  const products: ProductType[] = useMemo(() => purchaseInfo?.products.map((product) => ({
    name: product.productId.label,
    model: product.modelId.label,
    serial: product.seriesNumber,
  })) || submittedData?.billInformation?.products.map((product) => ({
    name: product.productName,
    model: product.modelName,
    serial: product.seriesNumber,
  })) || ([]), [purchaseInfo, submittedData]);

  const purchaseImages: string[] = purchaseInfo?.invoiceImages?.map((
    image
  ) => (image.type.includes('/pdf') ? `${URL.createObjectURL(image)}.pdf` : URL.createObjectURL(image)))
    || submittedData?.uploadImage?.images.map((img) => `${window.location.origin}${img}`) || [];

  /* Functions */

  const handleConfirm = () => {
    const uuid = localStorage.getItem(LOCALSTORAGE_KEYS.ELECTROLUX_UUID);
    if (!uuid) {
      setGlobalError({
        isBackHome: true,
        message: 'Phiên đăng ký của bạn đã hết hạn.<br />Vui lòng thực hiện lại'
      });
      return;
    }
    completeBillMutate(uuid);
  };

  const handleEnded = () => {
    setIsOpen(false);
    localStorage.removeItem(LOCALSTORAGE_KEYS.ELECTROLUX_UUID);
    localStorage.removeItem(LOCALSTORAGE_KEYS.ELECTROLUX_STEP);
    dispatch(resetData());
    window.open('https://www.electrolux.vn', '_self');
  };

  /* Effect */
  useDidMount(() => {
    const uuid = localStorage.getItem(LOCALSTORAGE_KEYS.ELECTROLUX_UUID);
    const currStep = localStorage.getItem(LOCALSTORAGE_KEYS.ELECTROLUX_STEP);
    if (currStep && uuid) {
      if (Number(currStep) === 5) {
        setIsOpen(true);
      }
    }
  });

  return (
    <PageLayout
      loading={submittedDataLoading}
      light
      footer={(
        <div className="p-confirmInfo_footer">
          <Typography.Text extendClasses="p-confirmInfo_notice" textStyle="center">
            (Kiểm tra lại thông tin sản phẩm trước khi nhấn Xác nhận)
          </Typography.Text>
          <div className="p-confirmInfo_btnGroup">
            <Button extendClasses="p-confirmInfo_back" type="button" onClick={() => navigate(ROUTES_PATH.PURCHASE_INFORMATION)}>
              Quay về
            </Button>
            <Button
              extendClasses="p-confirmInfo_confirm"
              onClick={handleConfirm}
              loading={isLoading}
              loadingIndicator={<Loading.CircleDashed width={20} color={COLORS.white} />}
            >
              Xác nhận
            </Button>
          </div>
        </div>
      )}
    >
      <div className="p-confirmInfo">
        <Container>
          <Typography.Heading
            type="h2"
            extendClasses="p-confirmInfo_heading"
            fontweight="600"
            textStyle="center"
          >
            Xác nhận thông tin
          </Typography.Heading>
          {profileField.map((field, idx) => (
            <div className="p-confirmInfo_field" key={field}>
              <Typography.Text extendClasses="p-confirmInfo_field_title">
                {field}
              </Typography.Text>
              <Typography.Text extendClasses="p-confirmInfo_field_value" fontweight="600" textStyle="right">
                {Object.values(profile)[idx]}
              </Typography.Text>
            </div>
          ))}
          <Typography.Text
            extendClasses="p-confirmInfo_purchase"
            fontweight="600"
          >
            THÔNG TIN MUA HÀNG
          </Typography.Text>
          {purchaseField.map((field, idx) => (
            <div className="p-confirmInfo_field" key={field}>
              <Typography.Text extendClasses="p-confirmInfo_field_title">
                {field}
              </Typography.Text>
              <Typography.Text extendClasses="p-confirmInfo_field_value" fontweight="600" textStyle="right">
                {Object.values(purchase)[idx]}
              </Typography.Text>
            </div>
          ))}
          {products.map((product, idx) => (
            <div className="p-confirmInfo_product" key={product.name}>
              <Typography.Text extendClasses="p-confirmInfo_field_title" fontweight="600">
                Sản phẩm
                {' '}
                {idx + 1}
              </Typography.Text>
              {productField.map((field) => (
                <div className="p-confirmInfo_field" key={field + product.name}>
                  <Typography.Text extendClasses="p-confirmInfo_field_title">
                    {field}
                  </Typography.Text>
                  <Typography.Text extendClasses="p-confirmInfo_field_value" fontweight="600" textStyle="right">
                    {Object.values(product)[productField.indexOf(field)]}
                  </Typography.Text>
                </div>
              ))}
            </div>
          ))}
          <div className="p-confirmInfo_field">
            <Typography.Text extendClasses="p-confirmInfo_field_title" fontweight="500">
              Hình ảnh hóa đơn
            </Typography.Text>
          </div>
          <div className="p-confirmInfo_invoice_list">
            {purchaseImages.map((image) => (
              <div className="p-confirmInfo_invoice">
                <Image extendClasses="p-confirmInfo_image" imgSrc={image.includes('.pdf') ? pdf : image} alt="bill" ratio={1} />
              </div>
            ))}
          </div>

        </Container>
        <CustomModal
          isOpen={isOpen}
          handleClose={() => setIsOpen(false)}
          variant="w700"
        >
          <div className="p-confirmInfo_thank">
            <Typography.Text fontweight="600" textStyle="center">
              Cảm ơn bạn đã tham gia
              <br />
              {' '}
              chương trình 3345 Quà Lớn Tri Ân
              <br />
              {' '}
              của Electrolux Việt Nam
            </Typography.Text>
            <div className="p-confirmInfo_thank_content">
              <Typography.Text fontweight="400" textStyle="center">
                Mã dự thưởng của Bạn sẽ được gửi về số
                <br />
                điện thoại đã đăng ký trong vòng 20 phút.
              </Typography.Text>
            </div>
            <div className="p-confirmInfo_thank_warranty">
              <Typography.Text fontweight="400" textStyle="center">
                Sản phẩm của bạn sẽ được tự động
                <br />
                đăng ký bảo hành theo thông tin đã điền.
              </Typography.Text>
            </div>
            <div className="p-confirmInfo_thank_notice">
              <Typography.Text textStyle="center">
                Tổng đài 1900 633 067
                <br />
                Electrolux xin chúc bạn nhiều Sức khỏe và May mắn!
              </Typography.Text>
            </div>
            <Button
              extendClasses="p-confirmInfo_thank_button"
              onClick={handleEnded}
            >
              Hoàn tất
            </Button>
          </div>
        </CustomModal>
        <CustomModal
          isOpen={!!globalError.message}
          handleClose={() => setGlobalError({
            isBackHome: false,
            message: ''
          })}
        >
          <div
            className="p-customerInfo_popup_error"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: globalError.message }}
          />
          <Button
            extendClasses="p-confirmInfo_thank_button"
            onClick={() => {
              if (globalError.isBackHome) {
                clearLocalStorage();
                navigate(ROUTES_PATH.HOME);
              } else {
                setGlobalError({
                  isBackHome: false,
                  message: ''
                });
              }
            }}
          >
            Đồng ý
          </Button>
        </CustomModal>
      </div>
    </PageLayout>
  );
};

export default ConfirmInfo;
