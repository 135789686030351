/* eslint import/no-extraneous-dependencies: warn */
import { AnimatePresence, Transition, motion } from 'framer-motion';
import React, {
  forwardRef, useEffect, useImperativeHandle, useRef, useState
} from 'react';

import mapModifiers, { generateArrayNumber } from 'utils/functions';

type PrizeItemRef = {
  handleDraw: () => void;
  handleReset: () => void;
  handleResult: (result: string) => void;
};

interface PrizeProps {
  duration: number;
  layoutFor?: LayoutFor;
  transition?: Transition;
}

export type PrizeRef = {
  handleDraw: () => void;
  handleResult: (result: string, sync?: boolean) => void;
  handleReset: () => void;
};

const PrizeItem = forwardRef<
  PrizeItemRef,
  {
    transition?: Transition;
  }
>(({
  transition = {
    y: {
      type: 'spring',
      damping: 40,
      duration: 0.7,
      from: 0,
      to: 0,
    },
    opacity: { type: 'just', duration: 0.8 },
  }
}, ref) => {
  const numbers = generateArrayNumber(10);
  const [index, setIndex] = useState(0);
  const [value, setValue] = useState<string | undefined>('x');
  const variants = {
    enter: () => ({
      zIndex: 0,
      y: -25,
      opacity: 0.05
    }),
    center: {
      zIndex: 1,
      y: 8,
      opacity: 1
    },
    exit: () => ({
      zIndex: 0,
      opacity: 0
    })
  };

  useEffect(() => {
    setTimeout(() => {
      let next = index + 1;
      if (next === numbers.length) {
        next = 0;
      }
      setIndex(next);
    }, 100);
  }, [index, numbers.length, setIndex]);

  useImperativeHandle(ref, () => ({
    handleDraw: () => setValue(undefined),
    handleReset: () => setValue('x'),
    handleResult: (val) => setValue(val.toString()),
  }));

  if (value) {
    return <p>{value === 'x' ? '' : value}</p>;
  }

  return (
    <AnimatePresence>
      <motion.p
        variants={variants}
        key={index}
        initial="enter"
        animate="center"
        exit="exit"
        transition={transition}
      >
        {numbers[index]}
      </motion.p>
    </AnimatePresence>
  );
});

const Prize = forwardRef<PrizeRef, PrizeProps>(({ layoutFor, transition, duration }, ref) => {
  const drawRef = useRef<Array<PrizeItemRef>>(Array(6).fill(null));
  useImperativeHandle(ref, () => ({
    handleDraw: () => {
      drawRef.current.forEach((element) => {
        element?.handleDraw();
      });
    },
    handleReset: () => {
      drawRef.current.forEach((element) => {
        element?.handleReset();
      });
    },
    handleResult: (val, sync) => {
      const arr = val.split('');
      if (sync) {
        drawRef.current.forEach((_, idx) => {
          drawRef.current[idx]?.handleResult(arr[idx]);
        });
      } else {
        drawRef.current.forEach((a, idx) => {
          setTimeout(() => {
            drawRef.current[idx]?.handleResult(arr[idx]);
          }, idx * duration);
        });
      }
    },
  }));

  return (
    <div className={mapModifiers('o-prize', layoutFor)}>
      {Array(6).fill(0).map((b, idx) => (
        <div
          className={mapModifiers('o-prize_number', layoutFor)}
          key={`number-${idx.toString()}`}
        >
          <PrizeItem
            transition={transition}
            ref={(refItem) => {
              if (refItem) {
                drawRef.current[idx] = refItem;
              }
            }}
          />
        </div>
      ))}
    </div>
  );
});

export default Prize;
