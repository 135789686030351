import React from 'react';

import Prize, { PrizeRef } from 'components/organisms/Prize';

interface FramePrizeProps {
  children?: React.ReactNode;
  drawValueRef: PrizeRef[];
  quantity: number;
  layoutFor?: LayoutFor;
  duration: number;
}

const FramePrize: React.FC<FramePrizeProps> = ({
  children, drawValueRef, quantity, layoutFor, duration
}) => (
  <div className="o-framePrize">
    <div className="o-framePrize_wrap">
      {Array(quantity).fill(null).map((_, idx) => (
        <div className="o-framePrize_item" key={`lucky-${idx.toString()}`}>
          <Prize
            duration={duration}
            layoutFor={layoutFor}
            // durationY={durationY}
            ref={(refItem) => {
              if (refItem) {
                // eslint-disable-line no-param-reassign
                drawValueRef[idx] = refItem;
              }
            }}
          />
        </div>
      ))}
    </div>
    {children}
  </div>
);

FramePrize.defaultProps = {
  children: undefined,
};

export default FramePrize;
