/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/media-has-caption */
import React, {
  useCallback, useEffect, useMemo, useRef, useState
} from 'react';
import { useMutation } from 'react-query';
import { Loading, Typography } from 'tfc-components';

import endAudio from 'assets/audio/wheel-end.mp3';
import loopAudio from 'assets/audio/wheel-loop.mp3';
import startAudio from 'assets/audio/wheel-start.mp3';
import vision from 'assets/images/vision.png';
import ButtonLuckyDraw from 'components/molecules/ButtonLuckyDraw';
import CirclePrize from 'components/molecules/CirclePrize';
import FramePrize from 'components/organisms/FramePrize';
import CustomModal from 'components/organisms/Modal';
import Notify from 'components/organisms/Notify';
import { PrizeRef } from 'components/organisms/Prize';
import DrawLayout from 'components/templates/DrawLayout';
import LayoutResult from 'components/templates/LayoutResult';
import useDebounce from 'hooks/useDebounce';
import useDidMount from 'hooks/useDidMount';
import { getLuckyDrawMatch1stService } from 'services/luckyDrawMatch';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getPrizesAsync } from 'store/prizes';
import { formatPhoneNumber } from 'utils/functions';

const PrizeNumberOne: React.FC = () => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector((state) => state.prizes.loading);
  const firstSummary = useAppSelector((state) => state.prizes.summary?.summary.first);
  const luckyDrawFirstDuration = useAppSelector((
    state
  ) => state.systems.system?.others.luckyDrawFirstDuration);
  /* States */
  const [isResult, setIsResult] = React.useState<boolean>(false);
  const [prizes, setPrizes] = React.useState<Array<Prize>>([]);
  const [drawIdx, setDrawIdx] = React.useState<number>(0);
  const [keyup, setKeyup] = useState<KeyboardEvent | undefined>(undefined);
  const [playing, setPlaying] = useState(false);
  const [isShowErrorNotify, setIsShowErrorNotify] = useState({
    isOpen: false,
    message: '',
  });
  const [ended, setEnded] = useState(false);
  const [isDraw, setIsDraw] = useState(false);

  /* Refs */
  const drawRef = useRef<Array<PrizeRef>>(Array(5).fill(null));
  const start = useRef<HTMLAudioElement>(null);
  const loop = useRef<HTMLAudioElement>(null);
  const end = useRef<HTMLAudioElement>(null);

  /* Variables */
  const buffer = 0.4;
  const timerDelayDraw = useMemo(() => (luckyDrawFirstDuration
    ? Math.ceil(luckyDrawFirstDuration * 1000 / 6)
    : 2500), [luckyDrawFirstDuration]);

  /* Functions */
  const result = useCallback((code: string) => {
    setPlaying(false);
    if (drawRef.current[drawIdx]) {
      drawRef.current[drawIdx].handleResult(code);
    }
    setDrawIdx((prev) => prev + 1);
    setTimeout(() => {
      setIsDraw(false);
    }, timerDelayDraw * 5);
  }, [drawIdx, timerDelayDraw]);

  const { mutate: drawMutate, data: currentDataDraw } = useMutation(
    'prizeNumberOne',
    (params: { phase: number, timeDelay: number }) => getLuckyDrawMatch1stService(params.phase),
    {
      onSuccess: (res, variables) => {
        setPrizes((prev) => [...prev, { luckyCode: res.luckyCodes[0] || '', phone: '' }]);
        setTimeout(() => {
          result(res.luckyCodes[0]);
        }, variables.timeDelay);
      },
      onError: (error: any) => {
        setPlaying(false);
        drawRef.current.forEach((element) => {
          element.handleReset();
        });
        if (error.length > 0) {
          switch (error[0].code) {
            case 'outOfRange':
              setIsShowErrorNotify({
                isOpen: true,
                message: 'Chưa đến thời gian để quay',
              });
              break;
            case 'limitedLuckyCode':
              setIsShowErrorNotify({
                isOpen: true,
                message: 'Đã hết mã quay số',
              });
              break;
            case 'luckyDrawLocked':
              setIsShowErrorNotify({
                isOpen: true,
                message: 'Hệ thống đang quay số. Vui lòng thử lại sau',
              });
              break;
            case 'phaseExisted':
              setIsShowErrorNotify({
                isOpen: true,
                message: 'Đợt quay đã tồn tại',
              });
              break;
            default:
              break;
          }
        } else {
          setIsShowErrorNotify({
            isOpen: true,
            message: 'Hệ thống đang có vấn đề',
          });
        }
      }
    }
  );

  const action = useCallback(async () => {
    setPlaying(true);
    setIsDraw(true);
    await start.current?.play();
    loop.current?.play();
    if (drawRef.current[drawIdx]) {
      drawRef.current[drawIdx].handleDraw();
    }
    drawMutate({ phase: drawIdx + 1, timeDelay: timerDelayDraw });
  }, [drawIdx, drawMutate, timerDelayDraw]);
  /* End Draw */

  const keyUpListener = useCallback((event: KeyboardEvent) => {
    setKeyup(event);
  }, []);

  const drawListener = useCallback((key: string) => {
    if (isResult && key === 'p') {
      setEnded(true);
    }
    if (!isResult && prizes.length === 5 && key === 'p' && !isDraw) {
      dispatch(getPrizesAsync()).unwrap().then((res) => {
        setIsResult(true);
        setPrizes(res.summary.first.matches.filter((it) => !!it).map((item) => ({
          luckyCode: item.luckyCode,
          phone: formatPhoneNumber(item.phone)
        })));
      }).catch((error: any) => {
        if (error.length > 0) {
          switch (error[0].code) {
            case 'outOfRange':
              setIsShowErrorNotify({
                isOpen: true,
                message: 'Chưa đến thời gian để quay',
              });
              break;
            case 'limitedLuckyCode':
              setIsShowErrorNotify({
                isOpen: true,
                message: 'Đã hết mã quay số',
              });
              break;
            case 'luckyDrawLocked':
              setIsShowErrorNotify({
                isOpen: true,
                message: 'Hệ thống đang quay số. Vui lòng thử lại sau',
              });
              break;
            default:
              break;
          }
        } else {
          setIsShowErrorNotify({
            isOpen: true,
            message: 'Hệ thống đang có vấn đề',
          });
        }
      });
    }
    if (key === ' ' && !isResult && drawIdx < drawRef.current.length && ((currentDataDraw && currentDataDraw.summary.allowContinue) || !currentDataDraw) && !isDraw) {
      action();
    }
  }, [action, dispatch, drawIdx, isResult, prizes, currentDataDraw, isDraw]);

  /* Effects */
  useDidMount(() => {
    dispatch(getPrizesAsync()).unwrap().then((res) => {
      const { matches, quantity } = res.summary.first;
      const matchsFilter = matches.filter((item) => !!item);
      if (matchsFilter.length === quantity) {
        setIsResult(true);
        setPrizes(matchsFilter.map((item) => ({
          luckyCode: item.luckyCode,
          phone: formatPhoneNumber(item.phone)
        })));
      } else {
        const indexCurrentDraw = matchsFilter.length;
        setDrawIdx(indexCurrentDraw);
        matchsFilter.forEach((item, index) => {
          drawRef.current[index].handleResult(item.luckyCode, true);
          setPrizes((prev) => [...prev, {
            luckyCode: item.luckyCode,
            phone: formatPhoneNumber(item.phone)
          }]);
        });
      }
    }).catch((error: any) => {
      if (error.length > 0) {
        switch (error[0].code) {
          case 'outOfRange':
            setIsShowErrorNotify({
              isOpen: true,
              message: 'Chưa đến thời gian để quay',
            });
            break;
          case 'limitedLuckyCode':
            setIsShowErrorNotify({
              isOpen: true,
              message: 'Đã hết mã quay số',
            });
            break;
          case 'luckyDrawLocked':
            setIsShowErrorNotify({
              isOpen: true,
              message: 'Hệ thống đang quay số. Vui lòng thử lại sau',
            });
            break;
          default:
            break;
        }
      } else {
        setIsShowErrorNotify({
          isOpen: true,
          message: 'Hệ thống đang có vấn đề',
        });
      }
    });
  });

  useEffect(() => {
    const loopListener = () => {
      if (!loop.current || !end.current) {
        return;
      }

      if (loop.current.currentTime > loop.current.duration - buffer) {
        if (!playing) {
          end.current.play();
        } else {
          loop.current.currentTime = 0;
          loop.current.play();
        }
      }
    };
    loop.current?.addEventListener('timeupdate', loopListener);
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      loop.current?.removeEventListener('timeupdate', loopListener);
    };
  }, [end, loop, playing]);

  useDebounce(() => {
    if (keyup) {
      drawListener(keyup.key);
    }
  }, 500, [keyup]);

  useEffect(() => {
    document.addEventListener('keydown', keyUpListener);
    return () => {
      document.removeEventListener('keydown', keyUpListener);
    };
  }, [keyUpListener]);

  return (
    <div className="p-prizeOne">
      <DrawLayout isResult={isResult}>
        {
          isLoading ? (
            <div className="loading">
              <Loading.Circle width={50} color="#21396f" />
            </div>
          ) : (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {
                isResult ? (
                  <LayoutResult
                    resultPrizes={[prizes]}
                    layoutFor="prize-1"
                    imagePrize={vision}
                    textPrize="GIẢI NHẤT"
                    textReward="Xe máy <br/> SH 125i"
                    heading="GIẢI NHẤT<br />SỐ LƯỢNG: 05 GIẢI"
                  />
                ) : (
                  <div className="p-prizeOne_container">
                    <div className="p-prizeTwo_phase">
                      <Typography.Text extendClasses="p-prizeTwo_title" fontweight="600" textStyle="center">
                        Giải Nhất
                        <br />
                        Số lượng: 05 giải
                      </Typography.Text>
                    </div>
                    <img alt="Reward" src={vision} className="p-prizeOne_reward" />
                    <div className="p-prizeOne_wrapCircle">
                      <CirclePrize textPrize="GIẢI NHẤT" textReward="Xe máy <br/> SH 125i" />
                    </div>
                    <FramePrize
                      duration={timerDelayDraw}
                      layoutFor="prize-1"
                      quantity={5}
                      drawValueRef={drawRef.current}
                    >
                      <ButtonLuckyDraw
                        disabled={!firstSummary?.allowContinue
                          || prizes.length === 5
                          || isDraw || (currentDataDraw && !currentDataDraw.summary.allowContinue)}
                        onHandleDraw={() => { }}
                        textButton="Quay số"
                      />
                    </FramePrize>
                    <audio id="startAudio" ref={start} src={startAudio} />
                    <audio id="loopAudio" ref={loop} src={loopAudio} />
                    <audio id="endAudio" ref={end} src={endAudio} />
                  </div>
                )
              }
            </>
          )
        }
        <Notify
          isOpen={isShowErrorNotify.isOpen}
          errorMessage={isShowErrorNotify.message}
          onHandleClose={() => setIsShowErrorNotify({ isOpen: false, message: '' })}
        />
        <CustomModal
          variant="maxWidth600"
          isOpen={ended}
          handleClose={() => setEnded(false)}
        >
          <div className="o-notify_popup">
            <Typography.Text textStyle="center" extendClasses="o-notify_popup_text" fontweight="700">
              Đã hết lượt quay cho Giải Nhất. Vui lòng chọn Giải khác.
            </Typography.Text>
          </div>
        </CustomModal>
      </DrawLayout>
    </div>
  );
};

export default PrizeNumberOne;
