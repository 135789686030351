/* eslint-disable @typescript-eslint/no-unused-vars */
import { yupResolver } from '@hookform/resolvers/yup';
// eslint-disable-next-line import/no-extraneous-dependencies
import { FineUploaderBasic } from 'fine-uploader/lib/core';
import React, {
  useCallback, useEffect, useMemo, useState
} from 'react';
import { Container } from 'react-bootstrap';
import {
  Controller, FormProvider, useFieldArray, useForm, useWatch
} from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import {
  Input, Typography, Button, Image, Loading
} from 'tfc-components';

import Product from './Product';

import pdf from 'assets/images/pdf.png';
import Icon from 'components/atoms/Icon';
import DatePickerCustom from 'components/molecules/DatePicker';
import Pulldown from 'components/molecules/Pulldown';
import CustomModal from 'components/organisms/Modal';
import PageLayout from 'components/organisms/PageLayout';
import { submitPurchaseInfoService, uploadPurchaseImagesService } from 'services/bills';
import { ChunkingImage } from 'services/bills/types';
import returnValidateErrorMessages from 'services/common/errors';
import { clearLocalStorage } from 'services/common/storage';
import getAllShopsService from 'services/shops';
import { ShopData } from 'services/shops/types';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { PurchaseInfoForm, savePurchaseInfo } from 'store/information';
import {
  COLORS, LOCALSTORAGE_KEYS, ROUTES_PATH, URL_CONST
} from 'utils/constants';
import mapModifiers, { formatDateYYYYMMDD } from 'utils/functions';
import useSchemas from 'utils/schemas';

const fieldToText = (field: string) => {
  const splitField = field.split('.');
  const value = splitField[splitField.length - 1];
  switch (value) {
    case 'images':
      return 'Ảnh hoá đơn';
    case 'buyDate':
      return 'Ngày mua';
    case 'shopId':
      return 'Đại lý';
    case 'shopName':
      return 'Tên đại lý';
    case 'shopAddress':
      return 'Địa chỉ';
    case 'modelId':
      return 'Model';
    case 'seriesNumber':
      return 'Số serial';
    default:
      return '';
  }
};

const PurchaseInfo: React.FC = () => {
  /* Hooks */
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { billInformation } = useSchemas();
  const purchaseInfoStore = useAppSelector((state) => state.information.purchaseInfo);
  const [isOpen, setIsOpen] = useState(false);
  const method = useForm<PurchaseInfoForm>({
    mode: 'onChange',
    resolver: yupResolver(billInformation),
    defaultValues: purchaseInfoStore || {
      buyDate: new Date().toISOString(),
      products: [
        {
          productId: undefined,
          modelId: undefined,
          seriesNumber: '',
        }
      ],
      invoiceImages: []
    }
  });

  const { fields: productFields, append: productAppend, remove } = useFieldArray({
    control: method.control,
    name: 'products',
  });

  const storeId = useWatch({ control: method.control, name: 'shopId' });
  const invoiceImages = useWatch({ control: method.control, name: 'invoiceImages' });
  const [imgErrId, setImgErrId] = useState<number[]>([]);
  const { system } = useAppSelector((state) => state.systems);
  const [inValidSize, setInValidSize] = useState(false);

  /* States */
  const [globalError, setGlobalError] = useState({
    isBackHome: false,
    message: ''
  });

  const [uuidImages, setUuidImages] = useState<ChunkingImage[]>([]);
  const [loading, setLoading] = useState(false);

  /* Queries */
  const { data: shops, isLoading: isLoadingShops } = useQuery<ShopData[]>(['getShops'], getAllShopsService);

  const shopOptions = useMemo(() => shops?.map((shop) => (
    { label: shop.shopData.name, value: shop.shopData.id })) || [], [shops]);

  const { mutate: mutateSubmitBillInfo, isLoading: billLoading } = useMutation('submitBillInfo', submitPurchaseInfoService, {
    onSuccess: () => {
      dispatch(savePurchaseInfo(method.getValues()));
      localStorage.setItem(LOCALSTORAGE_KEYS.ELECTROLUX_STEP, '3');
      navigate(ROUTES_PATH.CONFIRM_INFORMATION);
    },
    onError(error: any) {
      setUuidImages([]);
      if (error.length > 0) {
        error.forEach((err: ValidateError) => {
          if (err.field === 'uuid') {
            setGlobalError({
              isBackHome: true,
              message: 'Phiên đăng ký của bạn đã hết hạn.<br />Vui lòng thực hiện lại'
            });
          } else if (err.code === 'tooManyProducts') {
            setGlobalError({
              isBackHome: false,
              message: 'Mỗi số điện thoại chỉ tham gia tối đa 5 sản phẩm, bạn vui lòng sử dụng số điện thoại khác nếu muốn đăng ký thêm sản phẩm.'
            });
          } else if (err.code.includes('UniqueSeriesNumberAndModelName')) {
            method.setError(
              `${err.field}.seriesNumber` as any,
              { message: 'Số serial và số model đã tồn tại' }
            );
          } else {
            method.setError(
              err.field,
              { message: returnValidateErrorMessages(err, fieldToText(err.field)) }
            );
          }
        });
      } else {
        setGlobalError({
          isBackHome: false,
          message: 'Đã có lỗi xảy ra.<br />Vui lòng thực hiện lại'
        });
      }
    },
  });

  const { mutate: mutateInvoiceImages, isLoading: submitImagesLoading } = useMutation('submitInvoiceImages', (data:
    {
      uuid: string,
      chunkingData: ChunkingImage[],
      data: PurchaseInfoForm,
    }) => uploadPurchaseImagesService(
      { uuid: data.uuid, images: data.chunkingData }
    ), {
    onSuccess(_, variables) {
      const {
        buyDate, shopId, shopName, products
      } = variables.data;
      mutateSubmitBillInfo({
        uuid: variables.uuid,
        buyDate: formatDateYYYYMMDD(buyDate),
        shopId: shopId.value === 'other' ? undefined : shopId.value,
        shopName,
        products: products.map((product) => ({
          modelId: product.modelId.value,
          seriesNumber: product.seriesNumber
        }))
      });
    },
    onError(error: any) {
      if (error.length > 0) {
        error.forEach((err: any) => {
          if (err.field === 'uuid') {
            setGlobalError({
              isBackHome: true,
              message: 'Phiên đăng ký của bạn đã hết hạn.<br />Vui lòng thực hiện lại'
            });
          } else {
            method.setError(
              err.field,
              { message: returnValidateErrorMessages(err, fieldToText(err.field)) }
            );
          }
        });
      } else {
        setGlobalError({
          isBackHome: false,
          message: 'Đã có lỗi xảy ra.<br />Vui lòng thực hiện lại'
        });
      }
    },
  });

  /* Functions */
  const uploader = useMemo(() => new FineUploaderBasic({
    autoUpload: false,
    request: {
      endpoint: URL_CONST.MEDIA_FILE_UPLOAD_CHUNK,
    },
    chunking: {
      enabled: true,
      mandatory: true,
      partSize: 1000000, // 1MB,
    },
    callbacks: {
      onTotalProgress(totalUploadedBytes: number, totalBytes: number) {
        // console.log({ totalUploadedBytes, totalBytes });
        if (totalUploadedBytes === totalBytes) {
          setLoading(false);
        }
      },
      onComplete(id: number, name: string, res: any, xhr: XMLHttpRequest) {
        const uuid = uploader.getUuid(id);
        const file = uploader.getFile(id);
        setUuidImages((prev) => [...prev, { chunkedUuid: uuid, fileExtension: file.name.split('.').pop() }]);

        if (!res.success) {
          setImgErrId((prev) => [...prev, id]);
        }
      },
      // onError: async (id: number, name: string, errorReason: string, xhr: XMLHttpRequest) => {
      //   if (xhr.status === 401) {
      //     await refreshTokenService()
      //       .then((data) => {
      //         setAccessToken(data.accessToken);
      //         setRefreshToken(data.refreshToken);
      //         uploader.setCustomHeaders({ Authorization: `Bearer ${data.accessToken}` });
      //         uploader.retry(id);
      //       });
      //   }
      // },
    }
  }), []);
  const handleAddProduct = () => {
    if (productFields.length >= 5) {
      setIsOpen(true);
      return;
    }
    productAppend({
      productId: {
        label: '',
        value: ''
      },
      modelId: {
        label: '',
        value: ''
      },
      seriesNumber: '',
    });
  };

  const onSubmit = useCallback((data: PurchaseInfoForm) => {
    const uuid = localStorage.getItem(LOCALSTORAGE_KEYS.ELECTROLUX_UUID);
    if (!uuid) {
      setGlobalError({
        isBackHome: true,
        message: 'Phiên đăng ký của bạn đã hết hạn.<br />Vui lòng thực hiện lại'
      });
    } else if (!data.invoiceImages || data.invoiceImages?.length === 0) {
      method.setError('invoiceImages', { message: 'Vui lòng chụp ảnh hóa đơn' });
    } else {
      const hasInvalidSize = Array.from(data.invoiceImages).some((image) => image.size > 5242880);
      if (hasInvalidSize) {
        setInValidSize(true);
        Array.from(data.invoiceImages).forEach(
          (img, idx) => img.size > 5242880 && setImgErrId((prev) => [...prev, idx])
        );
      } else {
        setLoading(true);
        setImgErrId([]);
        uploader.addFiles(data.invoiceImages);
        uploader.uploadStoredFiles();
      }
    }
  }, [method, uploader]);

  const renderImages = useMemo(() => {
    if (!invoiceImages) {
      return null;
    }
    return Array.from(invoiceImages).map((image, idx) => (
      <div className="p-purchaseInfo_invoiceImages_item" key={`invoiceImage${idx.toString()}`}>
        <div
          className="p-purchaseInfo_invoiceImages_remove"
          onClick={() => {
            method.setValue('invoiceImages', Array.from(invoiceImages).filter((_, index) => index !== idx));
          }}
        >
          <Icon iconName="clear" size="24" />
        </div>
        <Image extendClasses={mapModifiers('p-purchaseInfo_invoiceImages_image', imgErrId.includes(idx) && 'error')} ratio={1} imgSrc={image.type.includes('pdf') ? pdf : URL.createObjectURL(image)} alt="invoiceImage" />
      </div>
    ));
  }, [invoiceImages, method, imgErrId]);

  useEffect(() => {
    setImgErrId([]);

    if (invoiceImages && invoiceImages.length > 0 && uuidImages.length === invoiceImages.length) {
      mutateInvoiceImages({
        uuid: localStorage.getItem(LOCALSTORAGE_KEYS.ELECTROLUX_UUID) || '',
        data: method.getValues(),
        chunkingData: uuidImages
      });
    }
  }, [invoiceImages, method, mutateInvoiceImages, uuidImages]);

  return (
    <PageLayout
      light
      loading={submitImagesLoading || billLoading || loading}
      footer={(
        <div className="p-purchaseInfo_footer">
          <Typography.Text extendClasses="p-purchaseInfo_footer_description" textStyle="center">
            (Kiểm tra lại thông tin sản phẩm trước khi nhấn tiếp tục)
          </Typography.Text>
          <Button
            extendClasses="p-purchaseInfo_footer_btn"
            type="submit"
            loading={submitImagesLoading || billLoading || loading}
            loadingIndicator={<Loading.CircleDashed width={20} color={COLORS.white} />}
            onClick={method.handleSubmit(onSubmit)}
          >
            <Typography.Text type="span">Tiếp tục</Typography.Text>
          </Button>
        </div>
      )}
    >
      <div className="p-purchaseInfo">
        <div className="p-purchaseInfo_header">
          <div className="p-purchaseInfo_header_back" onClick={() => navigate(ROUTES_PATH.CUSTOMER_INFORMATION)}>
            <Icon iconName="arrowBack" size="24" />
          </div>
          <Typography.Heading type="h3" extendClasses="p-purchaseInfo_header_title" textStyle="center" fontweight="600">
            Thông Tin Mua Hàng
          </Typography.Heading>
        </div>
        <Container>
          <FormProvider {...method}>
            {/* Info */}
            <div className="p-purchaseInfo_info">
              <Typography.Text extendClasses="p-purchaseInfo_info_title">
                Ngày mua
              </Typography.Text>
              <div className="p-purchaseInfo_info_input">
                <Controller
                  control={method.control}
                  name="buyDate"
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <>
                      <DatePickerCustom
                        handleSelectDate={onChange}
                        defaultDate={new Date(value)}
                        minDate={system?.eventStartDate
                          ? new Date(system.eventStartDate) : new Date()}
                        maxDate={new Date('2024-01-30')}
                      />
                      {error && (
                        <div className="form-error-message">{error.message}</div>
                      )}
                    </>
                  )}
                />
              </div>
            </div>

            <div className="p-purchaseInfo_info">
              <Typography.Text extendClasses="p-purchaseInfo_info_title">
                Đại lý mua hàng
              </Typography.Text>
              <div className="p-purchaseInfo_info_input">
                <Controller
                  name="shopId"
                  control={method.control}
                  render={({ field, fieldState: { error } }) => (
                    <>
                      <Pulldown
                        {...field}
                        placeholder="Chọn đại lý"
                        name="shopId"
                        handleChange={field.onChange}
                        options={[...shopOptions, { label: 'Khác', value: 'other' }]}
                        loading={isLoadingShops}
                        error={!!error?.message}
                      />
                      {error && (
                        <div className="form-error-message">{error.message}</div>
                      )}
                    </>
                  )}
                />
                {storeId?.value === 'other' && (
                  <Controller
                    name="shopName"
                    control={method.control}
                    render={({ field, fieldState: { error } }) => (
                      <>
                        <Input
                          {...field}
                          name="shopName"
                          bordered
                          extendClasses="custom-input p-purchaseInfo_info_inputmore"
                          placeholder="Nhập đại lý mua hàng"
                          onChange={field.onChange}
                        />
                        {error && (
                          <div className="form-error-message">{error.message}</div>
                        )}
                      </>
                    )}
                  />
                )}
              </div>
            </div>
            {productFields.map((_, idx) => (
              <Product
                key={`product-${idx.toString()}`}
                nestedIndex={idx}
                control={method.control}
                handleRemove={() => remove(idx)}
              />
            ))}
            <Button
              variant="dashed"
              icon={(
                <div className="p-purchaseInfo_addProductBtn_icon">
                  <Icon iconName="plus" size="24" />
                </div>
              )}
              contentDirection="rtl"
              extendClasses="p-purchaseInfo_addProductBtn u-mt-16"
              type="button"
              onClick={handleAddProduct}
            >
              <Typography.Text type="span">Thêm sản phẩm</Typography.Text>
            </Button>

            {/* Invoice Images */}
            <div className="p-purchaseInfo_invoiceImages u-mt-16">
              <div className="p-purchaseInfo_invoiceImages_title">
                <Typography.Text type="span" fontweight="600">
                  Chụp ảnh hóa đơn
                  {' '}
                </Typography.Text>
                <Typography.Text type="span">
                  (Tối đa 5 ảnh)
                </Typography.Text>
              </div>
              <div className="u-mt-12">
                <Typography.Text extendClasses="p-purchaseInfo_invoiceImages_description">
                  Ảnh chụp hóa đơn phải có đầy đủ ngày mua hàng, tên cửa hàng, số model, giá.
                  <br />
                  Chỉ hỗ trợ PDF, png hoặc jpg có dung lượng dưới 5MB.
                </Typography.Text>
              </div>
              <div className="p-purchaseInfo_invoiceImages_list">
                {renderImages}
                {(!invoiceImages || (invoiceImages && Array.from(invoiceImages).length < 5)) ? (
                  <label
                    htmlFor="invoiceImages"
                    className={mapModifiers(
                      'p-purchaseInfo_invoiceImages_capturePad',
                      invoiceImages && Array.from(invoiceImages).length > 0 && 'hasImage'
                    )}
                    onClick={() => {
                      method.setError('invoiceImages', {});
                    }}
                  >
                    <div className="p-purchaseInfo_invoiceImages_capturePadContent">
                      <Icon iconName="camera" size={invoiceImages && Array.from(invoiceImages).length > 0 ? '24' : '80'} />
                      <div className="u-mt-12">
                        <Typography.Text>
                          {invoiceImages && Array.from(invoiceImages).length > 0 ? 'Thêm ảnh' : 'Chụp ảnh hoặc tải ảnh hóa đơn'}
                        </Typography.Text>
                      </div>
                      <Controller
                        name="invoiceImages"
                        control={method.control}
                        render={({ field: { onChange }, fieldState: { error } }) => (
                          <>
                            <Input
                              id="invoiceImages"
                              name="invoiceImages"
                              extendClasses="p-purchaseInfo_invoiceImages_upload"
                              type="file"
                              onChange={(event) => {
                                if ([...Array.from(invoiceImages || []),
                                ...Array.from(event.target.files || [])].length > 5) {
                                  method.setError('invoiceImages', { message: 'Tối đa 5 ảnh' });
                                } else {
                                  onChange([...(invoiceImages || []),
                                  ...Array.from(event.target.files || [])]);
                                }
                              }}
                              multiple
                              accept="image/jpg,image/ipeg,image/png,.pdf"
                            />
                            {error && (
                              <div className="form-error-message">{error.message}</div>
                            )}
                          </>
                        )}
                      />
                    </div>
                  </label>
                ) : null}
              </div>
              {imgErrId.length > 0 && (
                <div className="form-error-message u-mt-8">
                  Hình số
                  {' '}
                  <strong>{imgErrId.map((id) => id + 1).join(', ')}</strong>
                  {' '}
                  {inValidSize ? 'vượt quá 5MB' : 'có lỗi tải lên. Vui lòng thử lại.'}
                </div>
              )}
            </div>
          </FormProvider>
          <CustomModal
            isOpen={!!globalError.message}
            handleClose={() => setGlobalError({
              isBackHome: false,
              message: ''
            })}
          >
            <div
              className="p-customerInfo_popup_error"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: globalError.message }}
            />
            <Button
              extendClasses="p-confirmInfo_thank_button"
              onClick={() => {
                if (globalError.isBackHome) {
                  clearLocalStorage();
                  navigate(ROUTES_PATH.HOME);
                } else {
                  setGlobalError({
                    isBackHome: false,
                    message: ''
                  });
                }
              }}
            >
              Đóng
            </Button>
          </CustomModal>
          <CustomModal
            isOpen={isOpen}
            variant="w400"
          >
            <div className="p-purchaseInfo_popup-wrapText">
              <Typography.Text
                textStyle="center"
                extendClasses="p-purchaseInfo_popup_text"
              >
                Mỗi số điện thoại chỉ tham gia tối đa 5 sản phẩm, bạn vui
                lòng sử dụng số điện thoại khác nếu muốn đăng ký thêm sản phẩm.
              </Typography.Text>
            </div>
            <Button
              extendClasses="p-purchaseInfo_popup_button"
              onClick={() => setIsOpen(false)}
            >
              Đóng
            </Button>
          </CustomModal>
        </Container>
      </div>
    </PageLayout>
  );
};

export default PurchaseInfo;
