import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import getSystemService from 'services/systems';
import { GeneralDataTypes, Translation } from 'services/systems/types';

interface SystemState {
  loading?: boolean;
  system?: GeneralDataTypes;
  pageTranslation?: Translation;
}

const initialState: SystemState = {
};

export const getSystemAsync = createAsyncThunk<GeneralDataTypes>(
  'systemReducer/getSystem',
  async (_, { rejectWithValue }) => {
    try {
      return await getSystemService();
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const systemSlice = createSlice({
  name: 'systemReducer',
  initialState,
  reducers: {
    setPageTranslation($state, action: PayloadAction<Translation | undefined>) {
      $state.pageTranslation = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(getSystemAsync.pending, ($state) => {
      $state.loading = true;
    });
    builder.addCase(getSystemAsync.fulfilled, ($state, action) => {
      $state.system = action.payload;
      $state.loading = false;
    });
    builder.addCase(getSystemAsync.rejected, ($state) => {
      $state.loading = false;
    });
  },
});

export const { setPageTranslation } = systemSlice.actions;

export default systemSlice.reducer;
